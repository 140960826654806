import React, { useState, useImperativeHandle, forwardRef } from "react";
import Toast from "./Toast";

export interface ToastManagerRef {
    addToast: (message: string) => void;
}

const ToastManager = forwardRef<ToastManagerRef>((_, ref) => {
    const [messages, setMessages] = useState<string[]>([]);

    const addToast = (message: string) => {
        setMessages((prev) => [...prev, message]);
    };

    const removeToast = (index: number) => {
        setMessages((prev) => prev.filter((_, i) => i !== index));
    };

    useImperativeHandle(ref, () => ({ addToast }));

    return (
        <div>
            {messages.map((message, index) => (
                <Toast
                    key={index}
                    message={message}
                    onClose={() => removeToast(index)}
                />
            ))}
        </div>
    );
});

export default ToastManager;
