import axios, { AxiosRequestConfig } from "axios";
import moment from "moment";
import ClientHome from "../types/clientHome";
import CoverageEvent from "../types/coverageEvent";
import CoverageRequirement, { ClientDaySchedule, CoverageRequirementLog } from "../types/coverageRequirement";
import Patient, { PatientDirectSupportTeam } from "../types/patient";
import RemoteSupportSession from "../types/remoteSupportSession";
import ServicePlan from "../types/servicePlan";
import ServiceType from "../types/serviceType";
import SpanRequirement, { SpanCoverageEvent } from "../types/spanRequirement";
import { convertUTCDateToLocalDate } from "../utilities/dateUtils";
import { getAPIToken} from "./billingService";
import { ClientScheduleNotice, ClientScheduleNoticeLog } from "../types/clientScheduleNotice";
import { Job } from "../types/backgroundJob";

const isDirectSupport = (serviceCode: string) : boolean => {
    var DSCodes = ["APC","AMW","FPC","FMW","AOC","FOC","SDC","NBC"];

    if(DSCodes.includes(serviceCode)){
        return true;
    } else {
        return false;
    }

}

const getDurationinHours = (startTime: Date, endTime: Date) : number => {
    const startHours = moment(startTime).format("H");
    const startMinutes = moment(startTime).format("mm");

    const startIndex = parseFloat(startHours) + parseFloat(startMinutes)/60.0;

    const endHours = moment(endTime).format("H");
    const endMinutes = moment(endTime).format("mm");

    const endIndex = parseFloat(endHours) + parseFloat(endMinutes)/60.0;

    return endIndex - startIndex < 0 ? 24 + (endIndex - startIndex) : endIndex - startIndex;
}


export const handleIncomingNotices = (notice: ClientScheduleNotice): ClientScheduleNotice => {
    notice.displayStartDate = moment.utc(notice.noticeStartDate).format("MM-DD-YYYY");
    notice.displayEndDate= moment.utc(notice.noticeEndDate).format("MM-DD-YYYY");

    notice.displayStartTime = moment(notice.noticeStartTime).format("h:mm A")
    notice.displayEndTime = moment(notice.noticeEndTime).format("h:mm A")

    notice.noticeStartTime = convertUTCDateToLocalDate(new Date(notice.noticeStartTime));
    notice.noticeEndTime = convertUTCDateToLocalDate(new Date(notice.noticeEndTime));

    // Duration in days
    notice.duration = moment(notice.noticeEndDate).diff(moment(notice.noticeStartDate), 'days') + 1;

    return notice;
}

export const handleIncomingShifts =  (shift: CoverageEvent): CoverageEvent => {
    shift.displayCoverageDate = moment.utc(shift.coverageDate).format("MM-DD-YYYY");
    shift.displayStartTime = moment(shift.startTime).format("h:mm A")
    shift.displayEndTime = moment(shift.endTime).format("h:mm A")


    shift.startTime = convertUTCDateToLocalDate(new Date(shift.startTime));
    shift.endTime = convertUTCDateToLocalDate(new Date(shift.endTime));
   

    return shift;
}

export const handleIncomingLogs =  (log: CoverageRequirementLog): CoverageRequirementLog => {
    // Parse string as UTC time
    const utcMoment = moment.utc(log.loggedTime); 

    // Convert to Eastern time 
    const easternMoment = utcMoment.tz('America/New_York');

    // Format as string
    const easternTimeString = easternMoment.format('MMMM DD, YYYY | h:mmA'); 
   
    log.displayLoggedTime = easternTimeString;

    return log;
}

export const handleIncomingNoticeLogs =  (log: ClientScheduleNoticeLog): ClientScheduleNoticeLog => {
    // Parse string as UTC time
    const utcMoment = moment.utc(log.loggedTime); 

    // Convert to Eastern time 
    const easternMoment = utcMoment.tz('America/New_York');

    // Format as string
    const easternTimeString = easternMoment.format('MMMM DD, YYYY | h:mmA'); 
   
    log.displayLoggedTime = easternTimeString;

    return log;
}

export const handleIncomingSessions =  (session: RemoteSupportSession): RemoteSupportSession => {
    session.displayStartDate = moment.utc(session.startDate).format("MM-DD-YYYY");
    session.displayEndDate= moment.utc(session.endDate).format("MM-DD-YYYY");
    session.displayStartTime = moment(session.startTime).format("h:mm A")
    session.displayEndTime = moment(session.endTime).format("h:mm A")

    const startHours = moment(session.startTime).format("H");
    const startMinutes = moment(session.startTime).format("mm");

    //console.log(startHours);
    //console.log(startMinutes);
    const startIndex = parseFloat(startHours) + parseFloat(startMinutes)/60.0;
    //console.log(startIndex);
    session.startIndex = startIndex;

    const endHours = moment(session.endTime).format("H");
    const endMinutes = moment(session.endTime).format("mm");

    const endIndex = parseFloat(endHours) + parseFloat(endMinutes)/60.0;

    if (endIndex < startIndex || session.multiDay){
        session.endIndex = 24;
        session.multiDayStartIndex = 0;
        session.multiDayEndIndex = endIndex;
    } else {
        session.endIndex = endIndex
    }

    //console.log(endHours);
    //console.log(endMinutes);

    session.startTime = convertUTCDateToLocalDate(new Date(session.startTime));
    session.endTime = convertUTCDateToLocalDate(new Date(session.endTime));
    session.startDate = convertUTCDateToLocalDate(new Date(session.startDate));
    session.endDate = convertUTCDateToLocalDate(new Date(session.endDate));

    if(session.assignedShifts != null){
        session.assignedShifts.forEach(function (shift) {
            shift.displayCoverageDate= moment.utc(shift.coverageDate).format("MM-DD-YYYY");
            shift.displayStartTime = moment(shift.startTime).format("h:mm A")
            shift.displayEndTime = moment(shift.endTime).format("h:mm A")
            console.log(shift.displayCoverageDate);
        });
    
    } 
  
    return session;
}

export const  handleIncomingSpanSessions = (session: RemoteSupportSession) : RemoteSupportSession => {
    session.displayStartDate = moment.utc(session.startDate).format("MM-DD-YYYY");
    session.displayEndDate= moment.utc(session.endDate).format("MM-DD-YYYY");
    session.displayStartTime = moment(session.startTime).format("h:mm A")
    session.displayEndTime = moment(session.endTime).format("h:mm A")

    const startHours = moment(session.startTime).format("H");
    const startMinutes = moment(session.startTime).format("mm");

    //console.log(startHours);
    //console.log(startMinutes);
    const startIndex = parseFloat(startHours) + parseFloat(startMinutes)/60.0;
    //console.log(startIndex);
    session.startIndex = startIndex;

    const endHours = moment(session.endTime).format("H");
    const endMinutes = moment(session.endTime).format("mm");

    const endIndex = parseFloat(endHours) + parseFloat(endMinutes)/60.0;

    if (endIndex < startIndex || session.multiDay){
        session.endIndex = 24;
        session.multiDayStartIndex = 0;
        session.multiDayEndIndex = endIndex;
    } else {
        session.endIndex = endIndex
    }

    session.colStart = 1;
    session.colEnd = 4;
    session.multiDayColStart = 1;
    session.multiDayColEnd = 4;
    //console.log(endHours);
    //console.log(endMinutes);

    session.startTime = convertUTCDateToLocalDate(new Date(session.startTime));
    session.endTime = convertUTCDateToLocalDate(new Date(session.endTime));
    session.startDate = convertUTCDateToLocalDate(new Date(session.startDate));
    session.endDate = convertUTCDateToLocalDate(new Date(session.endDate));

    if(session.assignedShifts != null){
        session.assignedShifts.forEach(function (shift) {
            shift.displayCoverageDate= moment.utc(shift.coverageDate).format("MM-DD-YYYY");
            shift.displayStartTime = moment(shift.startTime).format("h:mm A")
            shift.displayEndTime = moment(shift.endTime).format("h:mm A")
            console.log(shift.displayCoverageDate);
        });
    
    }
  
    return session;
}

export const MapOverlappingSessions = (sessions: RemoteSupportSession[], session: RemoteSupportSession) : RemoteSupportSession => {
    //const sessionStart = convertUTCDateToLocalDate(new Date(session.startTime));
    //const sessionEnd = convertUTCDateToLocalDate(new Date(session.endTime));
    
    sessions.forEach(function(s, index, arr) {
        if(moment(s.startDate).isSame(session.startDate, 'day') || moment(s.endDate).isSame(session.endDate, 'day')){
            if(s.remoteSupportSessionID !== session.remoteSupportSessionID && session.colEnd === 4){
                //const startTime = convertUTCDateToLocalDate(new Date(s.startTime));
                //const endTime = convertUTCDateToLocalDate(new Date(s.endTime));
    
                // console.log(moment(session.startTime));
                // console.log(moment(session.endTime));
                // console.log(moment(session.startTime).isBetween(moment(s.startTime), moment(s.endTime)));
                // console.log(moment(session.endTime).isBetween(moment(s.startTime), moment(s.endTime)));
                // console.log(moment(session.startTime).isSame(moment(s.startTime)) && moment(session.endTime).isSame(moment(s.endTime)))
                // console.log(moment(session.endTime));
                // console.log(moment(startTime));
                // console.log(moment(endTime));
                // if (sessionStart > startTime && sessionStart < endTime){
                //     console.log(sessionStart);
                //     console.log(sessionEnd);
                //     session.colStart = 3;
                //     session.colEnd = 2;
                //     if (s.colStart === 3 && s.colEnd === 2) {
                //         session.colStart = 1;
                //         session.colEnd = 2;
                //     } 
                //     return false;
                    
                // } 

                //console.log(session.providerName + " " + session.displayStartDate + " " + session.displayStartTime + " " + session.displayEndDate + " " + session.displayEndTime);

                if(session.displayStartDate === s.displayStartDate){
                    if (session.startIndex > s.startIndex && session.startIndex < s.endIndex){
                        // console.log(session);
                        // console.log(s);
                        session.colStart = 3;
                        session.colEnd = 2;
                        if (s.colStart === 3 && s.colEnd === 2) {
                            session.colStart = 1;
                            session.colEnd = 2;
                        } 
                        return session;
                    }

                    if (session.startIndex < s.startIndex && session.endIndex === s.endIndex){
                        // console.log(session);
                        // console.log(s);
                        session.colStart = 3;
                        session.colEnd = 2;
                        if (s.colStart === 3 && s.colEnd === 2) {
                            session.colStart = 1;
                            session.colEnd = 2;
                        } 
                        return session;
                    }

                    if (session.startIndex === s.startIndex && session.endIndex > s.endIndex){
                        // console.log(session);
                        // console.log(s);
                        session.colStart = 3;
                        session.colEnd = 2;
                        if (s.colStart === 3 && s.colEnd === 2) {
                            session.colStart = 1;
                            session.colEnd = 2;
                        } 
                        return session;
                    }
    
                    if (session.endIndex > s.startIndex && session.endIndex < s.endIndex){
                        // console.log(session);
                        // console.log(s);
                        session.colStart = 1;
                        session.colEnd = 2;
                        if (s.colStart === 1 && s.colEnd === 2){
                            session.colStart = 3;
                            session.colEnd = 2;
                        }
                        return session;
                    }
    
                    if (session.startIndex === s.startIndex && session.endIndex === s.endIndex){
                        // console.log(session);
                        // console.log(s);
                        session.colStart = 1;
                        session.colEnd = 2;
                        if (s.colStart === 1 && s.colEnd === 2){
                            session.colStart = 3;
                            session.colEnd = 2;
                        }
                        return session;
                    }
                }

                if(session.displayStartDate === s.displayEndDate && s.multiDayEndIndex !== 0){
                    if (session.startIndex > s.multiDayStartIndex && session.startIndex < s.multiDayEndIndex){
                        // console.log(session);
                        // console.log(s);
                        session.colStart = 3;
                        session.colEnd = 2;
                        if (s.multiDayColStart === 3 && s.multiDayColEnd === 2) {
                            session.colStart = 1;
                            session.colEnd = 2;
                        } 
                        return session;
                    }

                    if (session.startIndex < s.multiDayStartIndex && session.endIndex === s.multiDayEndIndex){
                        // console.log(session);
                        // console.log(s);
                        session.colStart = 3;
                        session.colEnd = 2;
                        if (s.colStart === 3 && s.colEnd === 2) {
                            session.colStart = 1;
                            session.colEnd = 2;
                        } 
                        return session;
                    }

                    if (session.startIndex === s.multiDayStartIndex && session.endIndex > s.multiDayEndIndex){
                        // console.log(session);
                        // console.log(s);
                        session.colStart = 3;
                        session.colEnd = 2;
                        if (s.colStart === 3 && s.colEnd === 2) {
                            session.colStart = 1;
                            session.colEnd = 2;
                        } 
                        return session;
                    }

                    if (session.endIndex > s.multiDayStartIndex && session.endIndex < s.multiDayEndIndex){
                        // console.log(session);
                        // console.log(s);
                        session.colStart = 1;
                        session.colEnd = 2;
                        if (s.multiDayColStart === 1 && s.multiDayColEnd === 2){
                            session.colStart = 3;
                            session.colEnd = 2;
                        }
                        return session;
                    }

                    if (session.startIndex === s.multiDayStartIndex && session.endIndex === s.multiDayEndIndex){
                        // console.log(session);
                        // console.log(s);
                        session.colStart = 1;
                        session.colEnd = 2;
                        if (s.multiDayColStart === 1 && s.multiDayColEnd === 2){
                            session.colStart = 3;
                            session.colEnd = 2;
                        }
                        return session;
                    }
                }

                if(session.displayEndDate === s.displayStartDate && session.multiDayEndIndex !== 0){
                    if (session.multiDayStartIndex > s.startIndex && session.multiDayStartIndex < s.endIndex){
                        // console.log(session);
                        // console.log(s);
                        session.multiDayColStart = 3;
                        session.multiDayColEnd = 2;
                        if (s.colStart === 3 && s.colEnd === 2) {
                            session.multiDayColStart = 1;
                            session.multiDayColEnd = 2;
                        } 
                        return session;
                    }

                    if (session.multiDayStartIndex < s.startIndex && session.multiDayEndIndex === s.endIndex){
                        // console.log(session);
                        // console.log(s);
                        session.colStart = 3;
                        session.colEnd = 2;
                        if (s.colStart === 3 && s.colEnd === 2) {
                            session.colStart = 1;
                            session.colEnd = 2;
                        } 
                        return session;
                    }

                    if (session.multiDayStartIndex === s.startIndex && session.multiDayEndIndex > s.endIndex){
                        // console.log(session);
                        // console.log(s);
                        session.colStart = 3;
                        session.colEnd = 2;
                        if (s.colStart === 3 && s.colEnd === 2) {
                            session.colStart = 1;
                            session.colEnd = 2;
                        } 
                        return session;
                    }
    
                    if (session.multiDayEndIndex > s.startIndex && session.multiDayEndIndex < s.endIndex){
                        // console.log(session);
                        // console.log(s);
                        session.multiDayColStart = 1;
                        session.multiDayColEnd = 2;
                        if (s.colStart === 1 && s.colEnd === 2){
                            session.multiDayColStart = 3;
                            session.multiDayColEnd = 2;
                        }
                        return session;
                    }
    
                    if (session.multiDayStartIndex === s.startIndex && session.multiDayEndIndex === s.endIndex){
                        // console.log(session);
                        // console.log(s);
                        session.multiDayColStart = 1;
                        session.multiDayColEnd = 2;
                        if (s.colStart === 1 && s.colEnd === 2){
                            session.multiDayColStart = 3;
                            session.multiDayColEnd = 2;
                        }
                        return session;
                    }
                }

                
    
    
                // if (moment(session.startTime).isBetween(moment(s.startTime), moment(s.endTime))){
                //     console.log(moment(session.startTime));
                //     console.log(moment(session.endTime));
                //     session.colStart = 3;
                //     session.colEnd = 2;
                //     if (s.colStart === 3 && s.colEnd === 2) {
                //         session.colStart = 1;
                //         session.colEnd = 2;
                //     } 
                //     return false;
                    
                // } 
    
                // if (sessionEnd > startTime && sessionEnd < endTime){
                //     session.colStart = 1;
                //     session.colEnd = 2;
                //     console.log(sessionStart);
                //     console.log(sessionEnd);
                //     if (s.colStart === 1 && s.colEnd === 2){
                //         session.colStart = 3;
                //         session.colEnd = 2;
                //     }
                //     return false;
                   
                // }

               
                
                // if (moment(session.endTime).isBetween(moment(s.startTime), moment(s.endTime))){
                //     session.colStart = 1;
                //     session.colEnd = 2;
                //     console.log(moment(session.startTime));
                //     console.log(moment(session.endTime));
                //     if (s.colStart === 1 && s.colEnd === 2){
                //         session.colStart = 3;
                //         session.colEnd = 2;
                //     }
                //     return false;
                   
                // }
    
                // if (sessionStart === startTime && sessionEnd === endTime){
                //     session.colStart = 1;
                //     session.colEnd = 2;
                //     console.log(sessionStart);
                //     console.log(sessionEnd);
                //     if (s.colStart === 1 && s.colEnd === 2){
                //         session.colStart = 3;
                //         session.colEnd = 2;
                //     }
                //     return false;
                // }

                
    
                // if (moment(session.startTime).isSame(moment(s.startTime)) && moment(session.endTime).isSame(moment(s.endTime))){
                //     session.colStart = 1;
                //     session.colEnd = 2;
                //     console.log(moment(session.startTime));
                //     console.log(moment(session.endTime));
                //     if (s.colStart === 1 && s.colEnd === 2){
                //         session.colStart = 3;
                //         session.colEnd = 2;
                //     }
                //     return false;
                // }
            }
        }
       
    })

    return session;
}

export const getActiveClients = async (handleAPICallError: (error: any) => void) : Promise<Patient[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'clients/active';
    return axios.get<Patient[]>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return [];
          });
}

export const getDSClientsForProvider = async (handleAPICallError: (error: any) => void) : Promise<Patient[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'clients/directsupport';
    return axios.get<Patient[]>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return [];
          });
}

export const getActiveDSClients = async (handleAPICallError: (error: any) => void) : Promise<Patient[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'clients/active/directsupport';
    return axios.get<Patient[]>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return [];
          });
}

export const getCoverageForSpan = async (patientID: string, startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<CoverageEvent[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL+"coverage/"+patientID+"/"+startDate+"/"+endDate;
    return axios.get<CoverageEvent[]>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data.map(handleIncomingShifts);
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return [];
          });
}

export const getSessionsForSpan = async (startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<RemoteSupportSession[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL+"sessions/"+startDate+"/"+endDate;
    return axios.get<RemoteSupportSession[]>(url,requestHeader).then(response => {
        if(response.status < 300) {
            if(response.data !== null){
                const allSessions = response.data.map(handleIncomingSpanSessions);
    
                const sortedSessions = allSessions.sort(
                    (objA, objB) => moment(objA.startTime).diff(moment(objB.startTime)),
                );
    
                allSessions.forEach(function(session, index, arr){
                    var mappedSession = MapOverlappingSessions(allSessions, session);
                    //console.log("mapped session");
                    //console.log(mappedSession);
                    arr[index] = mappedSession;
                });
    
                //console.log("Mapped Sessions:");
                //console.log(allSessions);
    
                return allSessions;
            } else {
                return [];
            }
        } else {
            handleAPICallError(response);
        }
        
    }).catch(error => {
        handleAPICallError(error);
        return [];
      });
}

export const getClientHomes = async (patientID: string, handleAPICallError: (error: any) => void) : Promise<ClientHome[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'clients/'+patientID+'/homes';
    return axios.get<ClientHome[]>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return [];
          });
}

export const getClientServiceType = async (patientID: string, handleAPICallError: (error: any) => void) : Promise<ServiceType[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'clients/'+patientID+'/serviceTypes';
    return axios.get<ServiceType[]>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return [];
          });
}

export const getClientByID = async (patientID: string, handleAPICallError: (error: any) => void) : Promise<Patient> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'clients/'+patientID+'/info';
    return axios.get<Patient>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getClientActiveISPByID = async (patientID: string, handleAPICallError: (error: any) => void) : Promise<ServicePlan> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'clients/'+patientID+'/servicePlan';
    return axios.get<ServicePlan>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return handleIncomingServicePlan(response.data);
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getPrimaryHomeForClient = async (patientID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'clients/'+patientID+'/home';
    return axios.get<ServicePlan>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getServicesForPlan = async (planID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'plans/'+planID+'/services';
    return axios.get<ServicePlan>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getOutcomeStepsForPlan = async (planID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'plans/'+planID+'/outcomes';
    return axios.get<ServicePlan>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

const handleIncomingServicePlan =  (servicePlan: ServicePlan): ServicePlan => {
    servicePlan.displayStartDate = moment.utc(servicePlan.startDate).format("MM-DD-YYYY");
    servicePlan.displayEndDate= moment.utc(servicePlan.endDate).format("MM-DD-YYYY");

    servicePlan.startDate = convertUTCDateToLocalDate(new Date(servicePlan.startDate));
    servicePlan.endDate = convertUTCDateToLocalDate(new Date(servicePlan.endDate));

    return servicePlan;
}

export const saveCoverageSpanRequirement = async (requirement: SpanRequirement, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'clients/'+requirement.patientID+'/requirement';

    return axios.post<any>(url, requirement, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const getSpanRequirementForPatient = async (patientID: string, handleAPICallError: (error: any) => void) : Promise<SpanRequirement> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'clients/'+patientID+'/requirement';

    return axios.get<SpanRequirement>(url,requestHeader).then((response) => {
        if(response.status < 300) {
            return handleIncomingSpanRequirement(response.data);
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

const handleIncomingSpanRequirement =  (requirement: SpanRequirement): SpanRequirement => {
    requirement.displayStartDate = moment.utc(requirement.startDate).format("MM-DD-YYYY");
    requirement.displayEndDate= moment.utc(requirement.endDate).format("MM-DD-YYYY");

    requirement.startDate = convertUTCDateToLocalDate(new Date(requirement.startDate));
    requirement.endDate = convertUTCDateToLocalDate(new Date(requirement.endDate));

    if(requirement.spanRequirementID.length !== 0){
        if(requirement.sundayRequirements !== null) {
            requirement.sundayRequirements = requirement.sundayRequirements.map(handleDailyRequirement);
        }
        if(requirement.mondayRequirements !== null) {
            requirement.mondayRequirements = requirement.mondayRequirements.map(handleDailyRequirement);
        }
        if(requirement.tuesdayRequirements !== null){
            requirement.tuesdayRequirements = requirement.tuesdayRequirements.map(handleDailyRequirement);
        }
        if(requirement.wednesdayRequirements !== null){
            requirement.wednesdayRequirements = requirement.wednesdayRequirements.map(handleDailyRequirement);
        }      
        if(requirement.thursdayRequirements !== null){
            requirement.thursdayRequirements = requirement.thursdayRequirements.map(handleDailyRequirement);
        }
        if(requirement.fridayRequirements !== null){
            requirement.fridayRequirements = requirement.fridayRequirements.map(handleDailyRequirement);
        }
        if(requirement.saturdayRequirements !== null){
            requirement.saturdayRequirements = requirement.saturdayRequirements.map(handleDailyRequirement);
        }
    }

    return requirement;
}

const handleDailyRequirement = (requirement: SpanCoverageEvent): SpanCoverageEvent => {
    requirement.displayStartTime = moment(requirement.startTime).format("h:mm A");
    requirement.displayEndTime= moment(requirement.endTime).format("h:mm A");

    requirement.startTime = convertUTCDateToLocalDate(new Date(requirement.startTime));
    requirement.endTime = convertUTCDateToLocalDate(new Date(requirement.endTime));

    return requirement;
}

export const updateCoverageSpanRequirement = async (requirement: SpanRequirement, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'clients/'+requirement.patientID+'/requirement';

    return axios.put<any>(url, requirement, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const updateSpanRequirementDataModel = async (patientID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'clients/'+patientID+'/requirement/dataModel';

    return axios.get<any>(url,requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });
}

export const getCoverageRequirementForSpan = async (patientID: string, startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<CoverageRequirement[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL+"requirement/"+patientID+"/"+startDate+"/"+endDate;
    return axios.get<CoverageRequirement[]>(url,requestHeader).then(response => {
        if(response.status < 300) {
            if(response.data != null) {
                return response.data.map(handleIncomingCoverageRequirements);
            } else {
                return null;
            }
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });
}

export const handleIncomingCoverageRequirements =  (shift: CoverageRequirement): CoverageRequirement => {
    shift.displayCoverageDate = moment.utc(shift.coverageDate).format("MM-DD-YYYY");
    shift.displayStartTime = moment(shift.startTime).format("h:mm A")
    shift.displayEndTime = moment(shift.endTime).format("h:mm A")


    shift.startTime = convertUTCDateToLocalDate(new Date(shift.startTime));
    shift.endTime = convertUTCDateToLocalDate(new Date(shift.endTime));
   

    return shift;
}

export const handleIncomingDailySchedule = (schedule: ClientDaySchedule): ClientDaySchedule => {
    schedule.displayDate = moment.utc(schedule.date).format("MM-DD-YYYY");
    schedule.dsHours = 0.0;
    schedule.rsHours = 0.0;

    if(schedule.requirements !== null){
        schedule.requirements = schedule.requirements.map(handleIncomingCoverageRequirements);

        schedule.requirements.forEach(function(requirement, index, arr){
            if(isDirectSupport(requirement.billingCode)){
                schedule.dsHours = schedule.dsHours + getDurationinHours(new Date(requirement.startTime), new Date(requirement.endTime));
            } else {
                if(requirement.status !== "Cancelled"){
                    schedule.rsHours = schedule.rsHours + getDurationinHours(new Date(requirement.startTime), new Date(requirement.endTime));
                }
            }
        });

        schedule.dsHours = Math.round(schedule.dsHours * 100) / 100;
        schedule.rsHours = Math.round(schedule.rsHours * 100) / 100;
    }
   
    return schedule;
}

export const saveCoverageRequirement = async (requirement: CoverageRequirement, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'clients/'+requirement.patientID+'/coverageRequirement';

    return axios.post<any>(url, requirement, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const saveRecurringCoverageRequirement = async (requirement: CoverageRequirement, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'clients/'+requirement.patientID+'/coverageRequirement/recurring';

    return axios.post<any>(url, requirement, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const populateScheduleForPatient = async (patientID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'clients/'+patientID+'/populate';

    return axios.get<any>(url,requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const populateLegacyScheduleForPatient = async (patientID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'clients/'+patientID+'/legacyPopulate';

    return axios.get<any>(url,requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const populateAgencySchedule = async (handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'agency/populate';

    return axios.get<any>(url,requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const getLegacyEventByID = async (eventID: string, handleAPICallError: (error: any) => void) : Promise<CoverageEvent> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'legacyEvents/'+eventID;
    return axios.get<CoverageEvent>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return handleIncomingShifts(response.data);
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const saveLegacyEvent = async (coverage: CoverageEvent, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'legacyEvents';

    return axios.post<any>(url, coverage, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const deleteLegacyEvent = async (coverage: CoverageEvent, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'legacyEvents/' + coverage.coverageEventID;

    return axios.delete<any>(url,requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const updateLegacyEvent = async (coverage: CoverageEvent, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'legacyEvents/' + coverage.coverageEventID;

    return axios.put<any>(url, coverage, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const getCoverageRequirementByID = async (reqID: string, handleAPICallError: (error: any) => void) : Promise<CoverageRequirement> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'clientRequirements/' + reqID;

    return axios.get<CoverageRequirement>(url, requestHeader).then((response) => {
        if(response.status < 300) {
            return handleIncomingCoverageRequirements(response.data);
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const markCoverageRequirementCancelled = async (reqID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'clientRequirements/' + reqID + '/cancel';

    return axios.get<any>(url, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const markCoverageRequirementCancelledWithReason = async (reqID: string, requirement: CoverageRequirement, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'clientRequirements/' + reqID + '/cancelWithReason';

    return axios.put<any>(url, requirement, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const updateCoverageRequirement = async (requirement: CoverageRequirement, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'clients/'+requirement.coverageRequirementID+'/coverageRequirement';

    return axios.put<any>(url, requirement, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const getOpenDSEventsForClientForSpan = async (patientID: string, startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<CoverageEvent[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL+"clients/" + patientID + "/openDirectSupportEvents/" + startDate + "/" + endDate;
    return axios.get<CoverageEvent[]>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data.map(handleIncomingShifts);
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return [];
          });
}

export const getAllOpenDSEventsForSpan = async (startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<CoverageEvent[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    var spanEnd = moment(endDate).add(1, 'days').format('YYYY-MM-DD');

    const url = baseURL+"openDirectSupportEvents/" + startDate + "/" + spanEnd;
    return axios.get<CoverageEvent[]>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                if(response.data){
                    return response.data.map(handleIncomingShifts);
                } else {
                    return [];
                }
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return [];
          });
}

export const getClientDirectSupportTeam = async (patientID: string, handleAPICallError: (error: any) => void) : Promise<PatientDirectSupportTeam> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL+"clients/" + patientID + "/directSupport";
    return axios.get<PatientDirectSupportTeam>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getAllClientsScheduleForDate = async (date: string, handleAPICallError: (error: any) => void) : Promise<ClientDaySchedule[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/json'
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'clients/schedule/' + date;

    return axios.get<ClientDaySchedule[]>(url, requestHeader).then((response) => {
        if(response.status < 300) {
            if (response.data !== null){
                return response.data.map(handleIncomingDailySchedule);
            }
            return null;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const getCoverageRequirementLogs = async (reqID: string, handleAPICallError: (error: any) => void) : Promise<CoverageRequirementLog[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'requirement/' + reqID + '/logs';

    return axios.get<CoverageRequirementLog[]>(url, requestHeader).then((response) => {
        if(response.status < 300) {
            if (response.data !== null){
                return response.data.map(handleIncomingLogs);
            }
            return null;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const getCoverageRequirementEvents = async (reqID: string, handleAPICallError: (error: any) => void) : Promise<CoverageEvent[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'requirement/' + reqID + '/events';

    return axios.get<CoverageEvent[]>(url, requestHeader).then((response) => {
        if(response.status < 300) {
            if (response.data !== null){
                return response.data.map(handleIncomingShifts);
            }
            return null;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });

}

export const addNewScheduleNotice = async (notice: ClientScheduleNotice, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();

    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'clientScheduleNotice';

    return axios.post<any>(url, notice, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });
}

export const getScheduleNoticesForSpan = async (patientID: string, startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<ClientScheduleNotice[]> => {
    const token = await getAPIToken();

    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'clients/' + patientID +  '/clientScheduleNotice/' + startDate + '/' + endDate;

    return axios.get<ClientScheduleNotice[]>(url, requestHeader).then((response) => {
        if(response.status < 300) {
            if (response.data !== null){
                return response.data.map(handleIncomingNotices);
            }
            return null;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });
}

export const markScheduleNoticeCancelledWithReason = async (noticeID: string, notice: ClientScheduleNotice, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'clientScheduleNotice/' + noticeID + '/cancel';

    return axios.put<any>(url, notice, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const getScheduleNoticeLogs = async (noticeID: string, handleAPICallError: (error: any) => void) : Promise<ClientScheduleNoticeLog[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'clientScheduleNotice/' + noticeID + '/logs';

    return axios.get<ClientScheduleNoticeLog[]>(url, requestHeader).then((response) => {
        if(response.status < 300) {
            if (response.data !== null){
                return response.data.map(handleIncomingNoticeLogs);
            }
            return null;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const getBackgroundJobs = async (handleAPICallError: (error: any) => void) : Promise<Job[]> => {
    const token = await getAPIToken();

    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'jobs/last-day';

    return axios.get<Job[]>(url, requestHeader).then((response) => {
        if(response.status < 300) {
            if (response.data !== null){
                return response.data;
            }
            return null;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });
}