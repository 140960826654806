import React, { useEffect } from "react";

interface ToastProps {
    message: string;
    onClose: () => void;
}

const Toast: React.FC<ToastProps> = ({ message, onClose }) => {
    useEffect(() => {
        // Close the toast after 3 seconds
        const timer = setTimeout(() => onClose(), 3000);
        return () => clearTimeout(timer);
    }, [onClose]);

    return (
        <div className="tw-fixed tw-top-4 tw-right-4 tw-bg-blue-600 tw-text-white tw-px-4 tw-py-2 tw-rounded tw-shadow-lg tw-animate-fade-in-out">
            {message}
        </div>
    );
};

export default Toast;
