import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { msalInstance } from "..";
import { legacyRequest } from "../authConfig";
import axios, { AxiosRequestConfig } from "axios";
import * as XLSX from 'xlsx';
import moment from 'moment';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import { getAPIToken } from "./billingService";

export const getLegacyAPIToken = async () : Promise<string | void> => {
    const account = msalInstance.getActiveAccount();

    // const response = await msalInstance.acquireTokenSilent({
    //     ...billingRequest,
    //     account: account
    //   });
    
    const token = msalInstance.acquireTokenSilent({
        ...legacyRequest,
        account: account
      }).then(tokenResponse => {
        // Do something with the tokenResponse
        return tokenResponse.idToken;
    }).catch(error => {
        if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return msalInstance.acquireTokenRedirect(legacyRequest)
        }
    
        // handle other errors
    });

    //console.log(response);

    return token;

    //return response.idToken;
}

export const getActivePlanForPatient = async (patientID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getLegacyAPIToken();

    console.log("Legacy token: " + token);

    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_LEGACYURL;
    const url = baseURL + `api/admin/clients/${patientID}/isps/active`;
    return axios.get<any>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

// Default values for static fields
 const defaultCounty = "FRANKLIN";
 const defaultPlaceOfService = "875 N High St, Columbus, OH - 43215";
 const defaultMedicaidID = "987654321"; // Random constant value for Medicaid ID
 const defaultProviderID = "2558727"; // Random constant value for Provider ID
 const defaultProviderName = "Ohio-At-Home Healthcare agency";

 // Default set of services
 const defaultServices = [
    {
      title: "General Supervision",
      frequency: "PerRequest",
      frequencyTitle: "Per Request",
    },
    {
      title: "Help Client with daily chores",
      frequency: "Daily",
      frequencyTitle: "Daily",
    },
    {
      title: "Drive Client to doctor",
      frequency: "Weekly",
      frequencyTitle: "Weekly",
    },
    {
      title: "Drive Client to community events",
      frequency: "Weekly",
      frequencyTitle: "Weekly",
    },
    {
      title: "Help client be more hygienic",
      frequency: "Daily",
      frequencyTitle: "Daily",
    }
  ];

// Function to generate random initials
function generateInitials() {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return letters[Math.floor(Math.random() * 26)] + letters[Math.floor(Math.random() * 26)];
}

export const getCompletedServicesForClientForDateSpan = async (patientID: string, startDate: string, endDate: string, handleAPICallError: (error: any) => void) => {
    const token = await getAPIToken();
    
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + `clients/${patientID}/documentedServices/${startDate}/${endDate}`;
    return axios.get<any>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getCompletedOutcomeStepsForClientForDateSpan = async (patientID: string, startDate: string, endDate: string, handleAPICallError: (error: any) => void) => {
    const token = await getAPIToken();

    const requestHeader: AxiosRequestConfig = {
      headers: {
          Authorization: `Bearer ${token}`
      },
      responseType: 'json',
      validateStatus: function (status) {
          return true;
      }
  }

  const baseURL = process.env.REACT_APP_SCHEDULINGURL;
  const url = baseURL + `clients/${patientID}/documentedGoals/${startDate}/${endDate}`;
  return axios.get<any>(url,requestHeader)
      .then(response => {
          if(response.status < 300) {
              return response.data;
          } else {
              handleAPICallError(response);
          }
      })
      .catch(error => {
          handleAPICallError(error);
          return null;
        });
}


// Function to create and download the Excel file using exceljs
export const createExcel = async (month, year, clientName) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Services');

    // Add metadata rows at the top
    worksheet.addRow([`Daily Rate Homemaker Personal Care (HPC) - Service Delivery Documentation Form`, '', `County: ${defaultCounty}`]);
    worksheet.addRow([`Individual's Name: ${clientName}`, '', `Service Month: ${moment().format('MMMM')}`, `Year: ${year}`]);
    worksheet.addRow([`Place of Service (Address): ${defaultPlaceOfService}`, '', `ISP Span: 4/16/14 - 4/15/25`]);
    worksheet.addRow([`Individual's Medicaid #: ${defaultMedicaidID}`]);
    worksheet.addRow([`Provider Name: ${defaultProviderName}`, `Provider #: ${defaultProviderID}`]);
    
    // Add some empty rows for space
    worksheet.addRow([]);
    worksheet.addRow([]);

    // Add headers with styles
    const headers = ["Service", "Frequency", "Responsible"];
    for (let i = 1; i <= moment(`${year}-${month}`, "YYYY-MM").daysInMonth(); i++) {
      headers.push(i.toString()); // Add headers for each day of the month
    }

    const headerRow = worksheet.addRow(headers);

    // Apply styles to header row
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'CCCCCC' } // Light gray background color
      };
      cell.font = { bold: true, color: { argb: '000000' } }; // Bold font and black color
      cell.alignment = { horizontal: 'center' }; // Center align text
    });

    // Add data for each default service
    defaultServices.forEach(service => {
      const row = [];
      row.push(service.title);
      row.push(service.frequencyTitle);
      row.push("Provider"); // Default responsible person

      // Populate the table with random initials based on the frequency
      const daysInMonth = moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
      for (let day = 1; day <= daysInMonth; day++) {
        if (service.frequency === "Daily" || (service.frequency === "Weekly" && (day % 7 === 0)) || service.frequency === "PerRequest") {
          row.push("");
        } else {
          row.push("");
        }
      }

      worksheet.addRow(row);
    });

    // Write the workbook to a buffer and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, `${clientName.split(' ')}_services_${month}_${year}.xlsx`);
  };


  export const createExcelWithRealData = async (month, year, patientData, addressData, planData, outcomeSteps) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Services');

    const clientName = `${patientData.firstName} ${patientData.lastName}`;
    const placeOfService = `${addressData.street1}${addressData.street2 ? ', ' + addressData.street2 : ''}, ${addressData.city}, ${addressData.state} ${addressData.postalCode}`;

    // Add metadata rows at the top with improved formatting
    worksheet.addRow([`Daily Rate ${patientData.patientDetail.procedureCode} - Service Delivery Documentation Form`, '', `County: ${patientData.patientDetail.county}`]);
    worksheet.addRow([`Individual's Name: ${clientName}`, '', `Service Month: ${moment(`${year}-${month}`, "YYYY-MM").format('MMMM')}`, `Year: ${year}`]);
    worksheet.addRow([`Place of Service (Address): ${placeOfService}`, '']);
    worksheet.addRow([`Individual's Medicaid #: ${patientData.patientDetail.medicaidNumber}`]);
    worksheet.addRow([`Provider Name: ${defaultProviderName}`, `Provider #: ${defaultProviderID}`]);
    
    // Add some empty rows for space
    worksheet.addRows([[], []]);

    worksheet.addRow(['All services are 1:1 unless otherwise noted.']);

    worksheet.addRow([]);

    // Add headers
    const daysInMonth = moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
    const headers = ["Service", "Frequency", "Responsible", ...Array.from({length: daysInMonth}, (_, i) => i + 1)];
    const headerRow = worksheet.addRow(headers);

    // Apply styles to header row
    headerRow.eachCell((cell) => {
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'CCCCCC' }
        };
        cell.font = { bold: true, color: { argb: '000000' } };
        cell.alignment = { horizontal: 'center' };
    });

    console.log("Grouping services...");
        const groupedServices = planData.services.reduce((acc, service) => {
            if (!acc[service.serviceTitle]) {
                acc[service.serviceTitle] = { ...service, frequencies: new Set() };
            }
            acc[service.serviceTitle].frequencies.add(service.frequency);
            return acc;
        }, {});

        console.log("Adding grouped service data...");
        Object.values(groupedServices).forEach((service: any, index) => {
            console.log(`Adding service ${index + 1}: ${service.serviceTitle}`);
            const row = [
                service.serviceTitle,
                Array.from(service.frequencies).join(", "),
                service.responsible,
                ...Array(daysInMonth).fill('')
            ];
            worksheet.addRow(row);
        });

        console.log("Grouping outcome steps...");
        const groupedOutcomeSteps = outcomeSteps.steps.reduce((acc, step) => {
            if (!acc[step.outcomeStepOverview]) {
                acc[step.outcomeStepOverview] = { ...step, frequencies: new Set() };
            }
            acc[step.outcomeStepOverview].frequencies.add(step.frequency);
            return acc;
        }, {});

        console.log("Adding grouped outcome steps...");
        // worksheet.addRow([]);
        worksheet.addRow(['Outcome Steps', 'Frequency', 'Responsible']);
        Object.values(groupedOutcomeSteps).forEach((step: any, index) => {
            console.log(`Adding outcome step ${index + 1}: ${step.outcomeStepOverview}`);
            worksheet.addRow([
                step.outcomeStepOverview, 
                Array.from(step.frequencies).join(", "), 
                step.responsible,
                ...Array(daysInMonth).fill('')
            ]);
        });

    // // Auto-fit columns
    // worksheet.columns.forEach(column => {
    //     column.width = Math.max(10, Math.min(30, column.width));
    // });

    // Write the workbook to a buffer and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, `${clientName.replace(/\s+/g, '_')}_services_${month}_${year}.xlsx`);
};

export const createExcelWithRealDataAndCompletedServices = async (month, year, patientData, addressData, planData, outcomeSteps, completedServices, completedGoals) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Services');

  const clientName = `${patientData.firstName} ${patientData.lastName}`;
  const placeOfService = `${addressData.street1}${addressData.street2 ? ', ' + addressData.street2 : ''}, ${addressData.city}, ${addressData.state} ${addressData.postalCode}`;

  // Add metadata rows at the top with improved formatting
  worksheet.addRow([`Daily Rate ${patientData.patientDetail.procedureCode} - Service Delivery Documentation Form`, '', `County: ${patientData.patientDetail.county}`]);
  worksheet.addRow([`Individual's Name: ${clientName}`, '', `Service Month: ${moment(`${year}-${month}`, "YYYY-MM").format('MMMM')}`, `Year: ${year}`]);
  worksheet.addRow([`Place of Service (Address): ${placeOfService}`, '']);
  worksheet.addRow([`Individual's Medicaid #: ${patientData.patientDetail.medicaidNumber}`]);
  worksheet.addRow([`Provider Name: ${defaultProviderName}`, `Provider #: ${defaultProviderID}`]);
  
  // Add some empty rows for space
  worksheet.addRows([[], []]);

  worksheet.addRow(['All services are 1:1 unless otherwise noted.']);

  worksheet.addRow([]);

  // Add headers
  const daysInMonth = moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
  const headers = ["Service", "Frequency", "Responsible", ...Array.from({length: daysInMonth}, (_, i) => i + 1)];
  const headerRow = worksheet.addRow(headers);

  // Apply styles to header row
  headerRow.eachCell((cell) => {
      cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'CCCCCC' }
      };
      cell.font = { bold: true, color: { argb: '000000' } };
      cell.alignment = { horizontal: 'center' };
  });

  console.log("Grouping services...");
  const groupedServices = planData.services.reduce((acc, service) => {
          if (!acc[service.serviceTitle]) {
              acc[service.serviceTitle] = { ...service, frequencies: new Set() };
          }
          acc[service.serviceTitle].frequencies.add(service.frequency);
          return acc;
      }, {});

  console.log("Adding grouped service data...");
  Object.values(groupedServices).forEach((service: any, index) => {
          console.log(`Adding service ${index + 1}: ${service.serviceTitle}`);
          const row = [
              service.serviceTitle,
              Array.from(service.frequencies).join(", "),
              service.responsible,
              ...getRowDataForService(service, month, year, completedServices)
          ];
          worksheet.addRow(row);
      });

      console.log("Grouping outcome steps...");
      const groupedOutcomeSteps = outcomeSteps.steps.reduce((acc, step) => {
          if (!acc[step.outcomeStepOverview]) {
              acc[step.outcomeStepOverview] = { ...step, frequencies: new Set() };
          }
          acc[step.outcomeStepOverview].frequencies.add(step.frequency);
          return acc;
      }, {});

      console.log("Adding grouped outcome steps...");
      // worksheet.addRow([]);
      worksheet.addRow(['Outcome Steps', 'Frequency', 'Responsible']);
      Object.values(groupedOutcomeSteps).forEach((step: any, index) => {
          console.log(`Adding outcome step ${index + 1}: ${step.outcomeStepOverview}`);
          worksheet.addRow([
              step.outcomeStepOverview, 
              Array.from(step.frequencies).join(", "), 
              step.responsible,
              ...getRowDataForOutcomeStep(step, month, year, completedGoals)
          ]);
      });

  // // Auto-fit columns
  // worksheet.columns.forEach(column => {
  //     column.width = Math.max(10, Math.min(30, column.width));
  // });

  // Write the workbook to a buffer and trigger download
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/octet-stream' });
  saveAs(blob, `${clientName.replace(/\s+/g, '_')}_services_${month}_${year}.xlsx`);
}

const getRowDataForService = (originalService, month, year, completedServices) => {
  const daysInMonth = moment(`${year}-${month}`, "YYYY-MM").daysInMonth();

  //console.log("Original service: " + originalService.personalServiceID);

  if(!completedServices || completedServices.length === 0) {
    console.log("No completed services found");
    return [...Array(daysInMonth).fill('')];
  }

  const completedServicesForService = completedServices.filter(service => service.originalServiceID === originalService.personalServiceID);

  if(!completedServicesForService || completedServicesForService.length === 0) {
    //console.log("No completed services found for service: " + originalService.serviceTitle);
    return [...Array(daysInMonth).fill('')];
  }

  // console.log("Completed services found for service: " + originalService.serviceTitle);
  // console.log(completedServicesForService);

  var rowData = [];

  // iterate through each day of the month
  for(let i = 1; i <= daysInMonth; i++) {
    const day = moment(`${year}-${month}-${i}`, "YYYY-MM-DD");
    const tomorrow = moment(day).add(1, 'day');

    //console.log("Checking day: " + day.format("YYYY-MM-DD"));

    const completedServicesForDay = completedServicesForService.filter(service => moment(service.completionTime).isBetween(day, tomorrow));

    var rowDataString = "";

    // console.log("Completed services for day: " + completedServicesForDay);
    // for(let j = 0; j < completedServicesForDay.length; j++) {
    //   console.log("Completed service: " + completedServicesForDay[j].completedBy);
    // }
    rowDataString = completedServicesForDay.map(service => service.completedBy).join(", ");

    //console.log("Row data string: " + rowDataString);

    rowData.push(rowDataString);
  }

  return rowData;
}

const getRowDataForOutcomeStep = (originalStep, month, year, completedGoals) => {
  const daysInMonth = moment(`${year}-${month}`, "YYYY-MM").daysInMonth();

  if(!completedGoals || completedGoals.length === 0) {
    console.log("No completed goals found");
    return [...Array(daysInMonth).fill('')];
  }

  const completedGoalsForStep = completedGoals.filter(goal => goal.originalOutcomeStepID === originalStep.outcomeStepID);

  if(!completedGoalsForStep || completedGoalsForStep.length === 0) {
    console.log("No completed goals found for step: " + originalStep.outcomeStepOverview);
    return [...Array(daysInMonth).fill('')];
  }

  var rowData = [];

  for(let i = 1; i <= daysInMonth; i++) {
    const day = moment(`${year}-${month}-${i}`, "YYYY-MM-DD");
    const tomorrow = moment(day).add(1, 'day');

    const completedGoalsForDay = completedGoalsForStep.filter(goal => moment(goal.completionTime).isBetween(day, tomorrow));

    var rowDataString = "";

    rowDataString = completedGoalsForDay.map(goal => goal.completedBy).join(", ");

    rowData.push(rowDataString);
  }

  return rowData;
}