import { Block, ButtonPrimary, ButtonSecondary } from "@medforall/medforall-component-library";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useAppContext } from "../../Context";
import AvatarNameInline, { AvatarNameInlineList } from "../avatars/AvatarNameInline";
import { ButtonError, ButtonTertiary } from "../buttons/Buttons";
import CalendarHeader, { CircleLabel } from "../calendars/CalendarUtils";
import DailyCalendar, { OpenBlock, Timeblock } from "../calendars/DailyCalendar";
import CommentBlock from "../containers/CommentBlock";
import { CancelType, Field, InputTimes, Label, Select, Textarea } from "../forms/formFields";
import { Div, LabelOutputs, Span } from "../utilities/Helpers";
import { EditScheduleDayPanel, ScheduleDayPanel } from "./SchedulePanel";
import CoverageRequirement, { CoverageRequirementLog } from "../../types/coverageRequirement";
import { getClientDirectSupportTeam, getCoverageRequirementEvents, getCoverageRequirementLogs, markCoverageRequirementCancelled, markCoverageRequirementCancelledWithReason, updateCoverageRequirement } from "../../apicalls/schedulingService";
import { LoadingSpinner } from "../portalComponents/loadingSpinner";
import { AddNewAssignedEventBottomPanel, EditCoverageRequirementBottomPanel } from "./PanelCTAs";
import moment from "moment";
import CoverageEvent from "../../types/coverageEvent";
import { start } from "repl";
import { addAssignedEventToDSRequirement, getClientDirectSupportProviders } from "../../apicalls/directSupportService";
import Employee from "../../types/employee";
import { IsRemoteSupport } from "../../utils/Helpers";

export const EditCoverDetailsPanel = props => {
	const navContext = useAppContext();
	const [shift, setShift] = useState<CoverageRequirement>(props.shift || null);

	const initialValues = {
		date: props.shift?.displayCoverageDate || "",
		start_time: props.shift?.displayStartTime || "",
		end_time: props.shift?.displayEndTime || "",
		shift_type: props.shift?.shiftType || "",
		req_reason: "",
	};

	const initialErrors = {
		date: false,
		start_time: false,
		end_time: false,
		shift_type: false,
		req_reason: false,
	};

	const initialErrorMessages = {
		date: '',
		start_time: '',
		end_time: '',
		shift_type: '',
		req_reason: '',
	};

	const onDiscard = () => {
		navContext.setPanelSize('tw-max-w-400px');
		navContext.setSideNav( <CoverageDetailsPanel date={moment(props.shift?.displayCoverageDate.replace(/-/g, "/")).format("MM/DD/YYYY")} shift={shift} serviceTypes={ props?.serviceTypes || null}/> );
		navContext.setSideNavActions(null);
		navContext.setSideNavBottom(null);
	}

	const onCancel = () => {
		
		navContext.setSideNavActions(null);
		navContext.setSideNavBottom(null);
		navContext.setSideNavTitle("Cancel Coverage");
		navContext.setSideNav( <CoverageDetailsCancelPanel shift={shift} serviceTypes={props?.serviceTypes || null}  refreshCalendar={props?.refreshCalendar || null} date={moment(props.shift?.displayCoverageDate.replace(/-/g, "/")).format("MM/DD/YYYY")}/> );
	}

	const onSave = async (values) => {
		const startTime = moment(values.date.replace(/-/g, "/") + " " + values.start_time)
        const endTime = moment(values.date.replace(/-/g, "/") + " " + values.end_time)

		const found = props?.serviceTypes.find(element => element.shiftType === values.shift_type);

		let updatedRequirement : CoverageRequirement = {
			coverageRequirementID: shift?.coverageRequirementID,
			patientID: shift?.patientID,
			recurrenceID: "",
			staffSize: found.staffSize,
			groupSize: found.groupSize,
			billingCode: found.billingCode,
			coverageDate: moment(values.date.replace(/-/g, "/")).format(),
			providerName: "",
			shiftType: found.shiftType,
			shiftTheme: 0,
			startTime: startTime.format(),
			displayStartTime: values.start_time,
			endTime: endTime.format(),
			displayEndTime: values.end_time,
			displayCoverageDate: values.date,
			recurrence: shift?.recurrence,
			status: "",
			repeatsSunday: shift?.repeatsSunday,
			repeatsMonday: shift?.repeatsMonday,
			repeatsTuesday: shift?.repeatsTuesday,
			repeatsWednesday: shift?.repeatsWednesday,
			repeatsThursday: shift?.repeatsThursday,
			repeatsFriday: shift?.repeatsFriday,
			repeatsSaturday: shift?.repeatsSaturday,
			recurrenceEndDate: moment(shift?.recurrenceEndDate).format(),
			reason: values.req_reason,
		}

		console.log(updatedRequirement);

		const result = await updateCoverageRequirement(updatedRequirement, navContext.handleAPICallError);

		if (!navContext.showErrorModal) {
			navContext.setSideNavTitle("Success");
			navContext.setSideNav( <CoverageDetailsPanelSuccess updating shift={updatedRequirement}/> );
			navContext.setSideNavActions(null);
			navContext.setSideNavBottom(null);

			if(props.refreshCalendar){
				console.log("refreshing calendar");
				await props.refreshCalendar();
			}
		}
		
	}

	useLayoutEffect(() => {
		navContext.setSideNavTitle("Edit Coverage Requirement");
		navContext.setValues(initialValues);
		navContext.setErrors(initialErrors);
		navContext.setErrorMessages(initialErrorMessages);
		navContext.setSideNavActions(null);
		navContext.setSideNavBottom(<EditCoverageRequirementBottomPanel onDiscard={onDiscard} onCancel={onCancel} onSave={onSave}/>)
		// navContext.setSideNavActions({
		// 	"editing": [{
		// 		"name": "Cancel Coverage",
		// 		"classes": 'tw-text-6',
		// 		"onClick": (e) => {
		// 			navContext.setSideNav( <CoverageDetailsPanel canceling  /> );
		// 			navContext.setSideNavActions(null);
		// 		 },
		// 		"title": `Go Back`
		// 	}, {
		// 		"onClick": (e) => {
		// 			navContext.setSideNav( <CoverageDetailsPanel  /> );
		// 			navContext.setSideNavActions(null);
		// 		 },
		// 		"title": `Go Back`
		// 	}, {
		// 		"name": "Save",
		// 		"onClick": (e) => {
		// 			navContext.setSideNavTitle("Changes Saved");
		// 			navContext.setSideNav( <CoverageDetailsPanelSuccess canceling  /> );
		// 			navContext.setSideNavActions(null);
		// 		 },
		// 		"title": `Save changes`
		// 	}]
		// });
	}, [])

	useLayoutEffect(() => {
		//TODO: Identify "id" to pass to client  <ClientInfoPanel />
		if (props.shift) setShift(props.shift)
	}, [props.shift])

    return <>
        <EditScheduleDayPanel edit={ true } shift={shift || null} serviceTypes={props?.serviceTypes || null}/>
    </>
}

const MakeComment = props => {
	const navContext = useAppContext();
	const [value, setValue] = useState<any>('');
	const ref = useRef(null);

	useEffect(() => {
		// navContext.setValues(value); // not needed ??....
	}, [value])

	return <>
		<Field>
			<Label for="demo-textarea-1">
				Notes
			</Label>
			<Textarea ref={ ref } placeholder="Type your notes here" className="tw-text-5 tw-leading-normal" onChange={ e => {
				setValue(e.target.value)
			}}>{ value }</Textarea>
			<div className="tw-flex tw-flex-items-stretch tw-justify-items-stretch -tw-mt-[1px]">
				<ButtonTertiary disabled={ (value.replaceAll(' ', '') === '') ? 'disabled' : null } className={`${ (value.replaceAll(' ', '') === '') && '' } tw-flex tw-items-center tw-justify-center tw-flex-1 !tw-rounded-none !tw-border !tw-border-r-0`} childClasses="tw-border-10 tw-border-accent !tw-text-5-alt !tw-leading-[1.125em] !tw-px-tiny !tw-pt-[0.5em] !tw-pb-[0.469em]" onClick={ e => {
					if (value.replaceAll(' ', '') !== '') {
						let text = `Are you sure you want to Discard? \n Your changes will be lost.`;
						if (window.confirm(text) == true) {
							setValue('');
							navContext.setSideNavActions(null);
							ref.current.value = '';
						}
					}
					e.preventDefault();
				}}>
					Discard
				</ButtonTertiary>
				<ButtonPrimary className="tw-flex tw-items-center tw-justify-center tw-flex-1 !tw-rounded-none !tw-border" childClasses="tw-border-10 tw-border-accent !tw-text-5-alt !tw-leading-[1.125em] !tw-px-tiny  !tw-pt-[0.5em] !tw-pb-[0.469em]" onClick={ e => {
					alert('add to notes service:\n' + value);
					navContext.setSideNavActions(null);
					e.preventDefault();
				}}>
					Post
				</ButtonPrimary>
			</div>
		</Field>
	</>
}


export const CoverageDetailsPanelSuccess = props => {
	const navContext = useAppContext();
	const [isNewRequirement, setIsNewRequirement] = useState<boolean>(false);
	const [isNewRecurringRequirement, setIsNewRecurringRequirement] = useState<boolean>(false);
	const [isUpdating, setIsUpdating] = useState<boolean>(false);
	const [isCanceling, setIsCanceling] = useState<boolean>(false);
	const [title, setTitle] = useState<string>('Saved coverage information');

	const [successMessage, setSuccessMessage] = useState<string>('Saved Successfully');

	const [shift, setShift] = useState<CoverageRequirement>(props.shift || null);

	useLayoutEffect(() => {
		if (props.newRequirement) {
			setIsNewRequirement(true);
			setTitle('Saved new coverage requirement');
		}
		if (props.newRecurringRequirement) {
			setIsNewRecurringRequirement(true);
			setTitle('Saved new recurring coverage requirement');
		}
		if (props.updating){
			setIsUpdating(true);
			setTitle('Updated coverage information');
		} 
		if (props.canceling){
			setIsCanceling(true);
			setTitle('Canceled coverage requirement');
			setSuccessMessage('Coverage Canceled Successfully');
		}
	}, [props.newRequirement, props.newRecurringRequirement, props.updating])

	return <>
		<div className="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden tw-h-full">
			<div className="tw-flex tw-flex-col tw-flex-grow tw-w-full tw-overflow-x-hidden tw-overflow-y-aut">
				<div className="tw-p-20px tw-space-y-20px tw-w-full tw-flex tw-flex-col tw-flex-grow ">
					<div className="tw-w-full tw-space-y-15px">
						<h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em]">
							<span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
								<span className="tw-text-dark tw-ml-5px">
									{title}
								</span>
							</span>
						</h3>
						<ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight">
							<li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-hash.svg" className="tw-opacity-70">
										Support Type
									</LabelOutputs>
								</span>
								<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									{ shift?.shiftType }
								</span>
							</li>
							<li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-date.svg" className="tw-opacity-70">
										Date
									</LabelOutputs>
								</span>
								<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									{ shift?.displayCoverageDate}
								</span>
							</li>
							<li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-time.svg" className="tw-opacity-70">
										Start Time
									</LabelOutputs>
								</span>
								<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									{shift?.displayStartTime}
								</span>
							</li>
							<li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-time.svg" className="tw-opacity-70">
										End Time
									</LabelOutputs>
								</span>
								<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									{shift?.displayEndTime}
								</span>
							</li>
							{ isNewRecurringRequirement && <li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-hash.svg" className="tw-opacity-70">
										Recurrence
									</LabelOutputs>
								</span>
								<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									{shift?.recurrence}
								</span>
							</li>}
							{ isNewRecurringRequirement && <li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-date.svg" className="tw-opacity-70">
										Recurrence End Date
									</LabelOutputs>
								</span>
								<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									{moment(shift?.recurrenceEndDate).format('M-D-YYYY')}
								</span>
							</li>}
						</ul>
					</div>
					<div className="tw-flex-grow tw-flex tw-flex-col tw-justify-center tw-my-auto tw-py-20px">
						<div className="tw-space-y-15px tw-text-center">
							<LabelOutputs className="tw-w-300px tw-bg-secondary/60 tw-inline-flex tw-rounded-3p tw-overflow-hidden tw-py-5px tw-px-10px" icon="/images/icon-saved.svg">
								<span className="tw-text-5">
									{successMessage}
								</span>
							</LabelOutputs>
							<ButtonSecondary className="tw-w-200px" onClick={ e => {
								e.preventDefault();
								navContext.closeSideNav();
							}}>
								Go Back to schedule
							</ButtonSecondary>
						</div>
					</div>
				</div>
			</div>
		</div>
	</>
}

export const CoverageDetailsCancelPanel = props => {
	const navContext = useAppContext();
	const [toggleRequiredInformation, setToggleRequiredInformation] = useState<boolean>(true);
	const [shift, setShift] = useState<CoverageRequirement>(props.shift || null);
	const [loading, setLoading] = useState<boolean>(false);
	const [reason, setReason] = useState<string>('');
	const [reasonDetail, setReasonDetail] = useState<string>('');
	const [reasonError, setReasonError] = useState<any>(false);
	const [reasonDetailError, setReasonDetailError] = useState<any>(false);

	useLayoutEffect(() => {
		if (props.shift) setShift(props.shift)
	}, [props.shift])

	const validateReason = () => {
		console.log(reason);

		if (reason === '') {
			setReasonError(true);
			return false;
		} else if (reason === 'Other' && reasonDetail === '') {
			setReasonDetailError(true);
			return false;
		} else {
			setReasonError(false);
			setReasonDetailError(false);
			return true;
		}

	}

	const handleCancel = async (e) => {


		e.preventDefault();

		const valid = validateReason();

		if(valid){
			setLoading(true);

			shift.reason = reason;
			if(reason === 'Other'){
				shift.reason = reasonDetail;
			}

			const result = await markCoverageRequirementCancelledWithReason(shift?.coverageRequirementID, shift, navContext.handleAPICallError);

			setLoading(false);

			if(!navContext.showErrorModal){
				navContext.setSideNavTitle("Success");
				navContext.setSideNav( <CoverageDetailsPanelSuccess canceling shift={shift}/> );
				navContext.setSideNavActions(null);
				navContext.setSideNavBottom(null);

				if(props.refreshCalendar){
					console.log("Refreshing calendar after cancel");
					await props.refreshCalendar();
				} else {
					console.log("No calendar to refresh");
				}
			} 
		}

		
	}

	return (<>
		<div className="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden tw-h-full">
			<div className="tw-flex-shrink-0 tw-w-full tw-overflow-x-hidden tw-overflow-y-auto">
				<div className="tw-p-20px tw-space-y-20px tw-w-full">

					<h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em]" onClick={ e => {
						let temp = toggleRequiredInformation;
						setToggleRequiredInformation( temp = !temp )
					}}>
						<span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
							<span className="tw-flex-grow">
							<CircleLabel className="tw-text-primary tw-space-x-15px" size={14} space="tw-space-x-15px">
								<span className="tw-text-dark tw-ml-5px">Required Information</span>
							</CircleLabel>
							</span>
							<span className="tw-ml-auto tw-flex tw-justify-start tw-items-center tw-flex-shrink-0 tw-w-20p">
								<img className="tw-w-full" src={`/images/icon-arrow-${ toggleRequiredInformation ? 'down-2' : 'up'}.svg`} alt="Icon" />
							</span>
						</span>
					</h3>
					{ toggleRequiredInformation ? <>
						<ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight">

							{ props.date ? <>
								<li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-180px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-date.svg" className="tw-opacity-70">
										Date
										</LabelOutputs>
									</span>
									<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
										{ props.date }
									</span>
								</li>
							</> : <></>}
							<li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-status.svg" className="tw-opacity-70">
										Status
									</LabelOutputs>
								</span>
								<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									<Span className="tw-flex-grow tw-pl-8">
										{shift?.status}
									</Span>
									<Span className="tw-ml-auto || tw-w-16p tw-h-16p tw-aspect-square tw-relative">
										<img className="tw-block tw-absolute tw-w-full tw-h-full tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-object-cover tw-object-center" src={`/images/icon-pie-_${ shift?.status.replaceAll(' ','-') }.svg`} alt="Partial Coverage" />
									</Span>
								</span>
							</li>
							<li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-hash.svg" className="tw-opacity-70">
										Support Type
									</LabelOutputs>
								</span>
								<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									{shift?.shiftType}
								</span>
							</li>
							<li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-time.svg" className="tw-opacity-70">
										Time Period
									</LabelOutputs>
								</span>
								<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									{shift?.displayStartTime} - {shift?.displayEndTime}
								</span>
							</li>
						</ul>
					</> : <></> }
				</div>
			</div>
				<div className="tw-pl-20px tw-pr-40px tw-space-y-20px">
					<div>
						<CancelType id="reason" onChange={ e => {
							setReason(e.target.value);
							// setValues({
							// 	...values,
							// 	"reason": e.target.value
							// })
						}} />
						{reasonError && <p className="tw-text-red-500">Select a reason for cancellation</p>}
						<fieldset className="tw-w-full tw-pt-10px">
							<Textarea id="reasonDetail" className={`${reasonDetailError ? '_form-error !tw-w-[calc(100%-1rem)]': '!tw-w-[calc(100%-1rem)]'}`} placeholder="Type your reason for cancelling here" onChange={ e => {
								// setValues({
								// 	...values,
								// 	"reasonDetail": e.target.value
								// })
								setReasonDetail(e.target.value);

							}}>
							</Textarea>
							{reasonDetailError && <p className="tw-text-red-500">Details needed</p>}
						</fieldset>
					</div>
					{loading ? <>
						<div className="tw-flex tw-justify-center tw-mx-4">
							<LoadingSpinner />
						</div>
					</>
					:
					<div>
						<h3 className="tw-font-primary-bold tw-font-5 tw-text-dark tw-py-20px">
							Are you sure about cancelling <Span>this coverage?</Span>
						</h3>
						<div className="tw-flex tw-justify-between tw-space-x-60px">
							<ButtonSecondary className="tw-basis-1/2" onClick={ e => {
								e.preventDefault();
								navContext.setPanelSize('tw-max-w-400px');
								navContext.setSideNav( <CoverageDetailsPanel date={moment(shift.displayCoverageDate.replace(/-/g, "/")).format("MM/DD/YYYY")} shift={shift} serviceTypes={ props?.serviceTypes || null}/> );
								navContext.setSideNavActions(null);
								navContext.setSideNavBottom(null);
							}}>
								No
							</ButtonSecondary>
							<ButtonPrimary className="tw-basis-1/2" onClick={handleCancel}>
								Yes, Cancel
							</ButtonPrimary>
						</div>
					</div>}
				</div>
			
		</div>
	</>);
}

const CoverageDetailsPanel = props => {
	const navContext = useAppContext();
	const [name, setName] = useState<any>(props.name);
	const [starting, setStarting] = useState<any>('8:30am');
	const [ending, setEnding] = useState<any>('2:15pm')
	const [showMakeComment, setShowMakeComment] = useState<boolean>(false);
	const [showDetails, setShowDetails] = useState<boolean>(false);
	const [isCanceling, setIsCanceling] = useState<boolean>(false);
	const [values, setValues] = useState<any>(null);
	const [toggleRequiredInformation, setToggleRequiredInformation] = useState<boolean>(true);
	const [toggleInformation, setToggleInformation] = useState<boolean>(true);
	const [staff, setStaff] = useState<string>('');
	const [startTime, setStartTime] = useState<string>('');
	const [endTime, setEndTime] = useState<string>('');
	const [shift, setShift] = useState<CoverageRequirement>(props.shift || null);
	const [requirementLogs, setRequirementLogs] = useState<CoverageRequirementLog[]>(null);
	const [logsLoading, setLogsLoading] = useState<boolean>(false);
	const [assignmentsLoading, setAssignmentsLoading] = useState<boolean>(false);
	const [requirementEvents, setRequirementEvents] = useState<CoverageEvent[]>(null);
	const [directSupportTeam, setClientDirectSupportTeam] = useState<Employee[]>([]);

	const handleOnClick = e => {
		e.preventDefault();
		if (props.onClick) props.onClick(e);
	}

	useLayoutEffect(() => {
		//TODO: Identify "id" to pass to client  <ClientInfoPanel />
		if (props.name) setName(props.name)
		if (props.shift) 
			{
				setShift(props.shift)
				console.log("Shift: ", props.shift);
			}

		
	}, [props.name, props.shift])

	useLayoutEffect(() => {
		navContext.setSideNavTitle("Coverage Details")

		const fetchRequirementLogs = async () => {
			setLogsLoading(true);

			var requirementID = props.shift?.coverageRequirementID;

			if (requirementID) {
				const response = await getCoverageRequirementLogs(requirementID, navContext.handleAPICallError);

				console.log(response);

				setRequirementLogs(response);

				setLogsLoading(false);
			}
		};

		const fetchRequirementEvents = async () => {
			setAssignmentsLoading(true);

			var requirementID = props.shift?.coverageRequirementID;

			if (requirementID) {
				const response = await getCoverageRequirementEvents(requirementID, navContext.handleAPICallError);

				// console.log("Events");
				// console.log(response);

				var startTime = moment(props.shift?.displayCoverageDate.replace(/-/g, "/") + " " + props.shift?.displayStartTime);
				var endTime = moment(props.shift?.displayCoverageDate.replace(/-/g, "/") + " " + props.shift?.displayEndTime);

				if (endTime.isBefore(startTime)) {
					endTime.add(1, 'days');
				}

				var allEvents : CoverageEvent[] = [];

				if (response === null || response.length === 0) {
				} else {
					var sortedEvents = response.sort((a, b) => {
						return moment(a.startTime).diff(moment(b.startTime));
					});
	
					
	
					sortedEvents.forEach(event => {
						var eventStartTime = moment(props.shift?.displayCoverageDate.replace(/-/g, "/") + " " + event.displayStartTime);
						var eventEndTime = moment(props.shift?.displayCoverageDate.replace(/-/g, "/") + " " + event.displayEndTime);
	
						if (eventEndTime.isBefore(eventStartTime)) {
							eventEndTime.add(1, 'days');
						}
	
						if (eventStartTime.isSame(startTime)) {
							allEvents.push(event);
							startTime = eventEndTime;
						}
	
						if (startTime.isBefore(eventStartTime)) {
							var emptyEvent : CoverageEvent = {
								coverageEventID: "",
								patientID: "",
								patientName: "",
								providerID: "",
								staffSize: 0,
								groupSize: 0,
								billingCode: "",
								coverageDate: props.shift?.displayCoverageDate,
								providerName: "",
								shiftType: "",
								shiftTheme: 0,
								startTime: startTime.toDate(),
								displayStartTime: startTime.format("h:mm A"),
								endTime: eventStartTime.toDate(),
								displayEndTime: eventStartTime.format("h:mm A"),
								displayCoverageDate: props.shift?.displayCoverageDate,
								status: "Open"
							};
	
							allEvents.push(emptyEvent);
	
							allEvents.push(event);
	
							startTime = eventEndTime;
						}
					});

					console.log("sortedEvents:", sortedEvents);
				}

				

				if (endTime.isAfter(startTime)) {
					var emptyEvent : CoverageEvent = {
						coverageEventID: "",
						patientID: "",
						patientName: "",
						providerID: "",
						staffSize: 0,
						groupSize: 0,
						billingCode: "",
						coverageDate: props.shift?.displayCoverageDate,
						providerName: "",
						shiftType: "",
						shiftTheme: 0,
						startTime: startTime.toDate(),
						displayStartTime: startTime.format("h:mm A"),
						endTime: endTime.toDate(),
						displayEndTime: endTime.format("h:mm A"),
						displayCoverageDate: props.shift?.displayCoverageDate,
						status: "Open"
					};

					allEvents.push(emptyEvent);
				}

				
				console.log("response:", response);
				console.log("allEvents:", allEvents);

				setRequirementEvents(allEvents);



				setAssignmentsLoading(false);
			}
		};

		const fetchClientDirectSupportTeam = async () => {
			const response = await getClientDirectSupportProviders(props.shift.patientID, navContext.handleAPICallError);
			// setClientDirectSupportTeam(response);

			console.log("Client Direct Support Team");
			console.log(response);

			if (response) {
				setClientDirectSupportTeam(response);
			}
		}

		fetchRequirementLogs();

		fetchRequirementEvents();

		fetchClientDirectSupportTeam();
	}, [])

	useLayoutEffect(() => {
		if (props.canceling) setIsCanceling(true);
	}, [props.canceling])

	useEffect(() => {
		navContext.setValues(values);
	}, [values])

	const cancelAssignment = () => {
		setShowDetails(false);
		navContext.setSideNavBottom(null);
	}

	const saveAssignment = async (assignee: any) => {
		setShowDetails(false);
		//navContext.setSideNavBottom(null);

		console.log(assignee);

		var newCoverageEvent : CoverageEvent = {
			coverageEventID: "",
			patientID: props.shift?.patientID,
			patientName: props.shift?.patientName,
			providerID: assignee.staffID,
			staffSize: props.shift?.staffSize,
			groupSize: props.shift?.groupSize,
			billingCode: props.shift?.billingCode,
			coverageDate: props.shift?.coverageDate,
			providerName: assignee.staff,
			shiftType: props.shift?.shiftType,
			shiftTheme: 0,
			startTime: new Date(props.shift?.displayCoverageDate + " " + assignee.startTime),
			displayStartTime: assignee.startTime,
			endTime: new Date(props.shift?.displayCoverageDate + " " + assignee.endTime),
			displayEndTime: assignee.endTime,
			displayCoverageDate: props.shift?.displayCoverageDate,
			status: "Assigned"
		};

		console.log(newCoverageEvent);

		var result = await addAssignedEventToDSRequirement(props.shift?.coverageRequirementID, newCoverageEvent, navContext.handleAPICallError);

		navContext.setSideNavActions(null);
		navContext.setSideNavBottom(null);

	}



	const handleTimeblockEvent = (e = null, start = null, end = null, staff = null) => {
		if (e) e.preventDefault();

		var isRemoteSupport = IsRemoteSupport(props.shift?.billingCode);

		if(!isRemoteSupport) {
			if (showDetails === false) {
				setShowDetails(true);

				console.log("Start: ", start);
				console.log("End: ", end);
				console.log("Staff: ", staff);
	
				if (start && end) {
					navContext.setValues({
						startTime: start,
						endTime: end
					})
				}
	
				if (start && end && staff) {
					navContext.setValues({
						startTime: start,
						endTime: end,
						staff: staff
					})
				}

				navContext.setSideNavBottom(<AddNewAssignedEventBottomPanel onCancel={cancelAssignment} onSave={saveAssignment}/>)
	
				// navContext.setSideNavActions({
				// 	"ctas": [{
				// 		"name": "Back",
				// 		"onClick": (e) => {
				// 			setShowDetails(false)
				// 			navContext.setSideNavActions(null);
				// 		}
				// 	}, {
				// 		"primary": true,
				// 		"name": "Assign",
				// 		"onClick": (e) => {
				// 			console.log(navContext.values);

				// 			//navContext.setSideNavActions(null);
				// 		}
				// 	}]
				// });
			} else {
				setShowDetails(false);
			}
		}

		
	}

	return <>
		<div className="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden tw-h-full">
			<div className="tw-flex-shrink-0 tw-w-full tw-overflow-x-hidden tw-overflow-y-auto">
				<div className="tw-p-20px tw-space-y-20px tw-w-full">

					<h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em]" onClick={ e => {
						let temp = toggleRequiredInformation;
						setToggleRequiredInformation( temp = !temp )
					}}>
						<span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
							<span className="tw-flex-grow">
							<CircleLabel className="tw-text-primary tw-space-x-15px" size={14} space="tw-space-x-15px">
								<span className="tw-text-dark tw-ml-5px">Required Information</span>
							</CircleLabel>
							</span>
							<span className="tw-ml-auto tw-flex tw-justify-start tw-items-center tw-flex-shrink-0 tw-w-20p">
								<img className="tw-w-full" src={`/images/icon-arrow-${ toggleRequiredInformation ? 'down-2' : 'up'}.svg`} alt="Icon" />
							</span>
						</span>
					</h3>
					{ toggleRequiredInformation ? <>
						<ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight">

							{ props.date ? <>
								<li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-180px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-date.svg" className="tw-opacity-70">
										Date
										</LabelOutputs>
									</span>
									<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
										{ props.date }
									</span>
								</li>
							</> : <></>}
							<li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-status.svg" className="tw-opacity-70">
										Status
									</LabelOutputs>
								</span>
								<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									<Span className="tw-flex-grow tw-pl-8">
										{shift?.status}
									</Span>
									<Span className="tw-ml-auto || tw-w-16p tw-h-16p tw-aspect-square tw-relative">
										<img className="tw-block tw-absolute tw-w-full tw-h-full tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-object-cover tw-object-center" src={`/images/icon-pie-_${ shift?.status.replaceAll(' ','-') }.svg`} alt="Partial Coverage" />
									</Span>
								</span>
							</li>
							<li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-hash.svg" className="tw-opacity-70">
										Support Type
									</LabelOutputs>
								</span>
								<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									{shift?.shiftType}
								</span>
							</li>
							<li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-time.svg" className="tw-opacity-70">
										Time Period
									</LabelOutputs>
								</span>
								<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									{shift?.displayStartTime} - {shift?.displayEndTime}
								</span>
							</li>
							{/* <li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-edit.svg" className="tw-opacity-70">
										Created On
									</LabelOutputs>
								</span>
								<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									03/17/2021
								</span>
							</li> */}
						</ul>

					{!isCanceling && (shift.status !== "Cancelled") && (navContext.scheduleAdmin || navContext.clientScheduleAdmin) && 
					
					<div className="tw-flex tw-justify-end tw-pr-20px tw-pb-10px">
						<ButtonError className="tw-min-w-80px tw-mx-4" onClick={ e => {
							e.preventDefault();
							navContext.setSideNavActions(null);
							navContext.setSideNavBottom(null);
							navContext.setSideNavTitle("Cancel Coverage");
							navContext.setSideNav( <CoverageDetailsCancelPanel shift={shift} serviceTypes={props?.serviceTypes || null}  refreshCalendar={props?.refreshCalendar || null} date={moment(props.shift?.displayCoverageDate.replace(/-/g, "/")).format("MM/DD/YYYY")}/> );
							//navContext.setSideNav(<EditCoverDetailsPanel changeRequirements shift={shift || null} serviceTypes={props?.serviceTypes || null}  refreshCalendar={props?.refreshCalendar || null}/>); // pass props in this
						}}>
							Void
						</ButtonError>

						<ButtonPrimary className="tw-min-w-80px" onClick={ e => {
							e.preventDefault();
							navContext.setSideNav(<EditCoverDetailsPanel changeRequirements shift={shift || null} serviceTypes={props?.serviceTypes || null}  refreshCalendar={props?.refreshCalendar || null}/>); // pass props in this
						}}>
							Edit
						</ButtonPrimary>
					</div> }
					</> : <></> }
				</div>
			</div>
			{ isCanceling ? <>
				<div className="tw-pl-20px tw-pr-40px tw-space-y-20px">
					<div>
						<CancelType id="reason" onChange={ e => {
							setValues({
								...values,
								"reason": e.target.value
							})
						}} />
						<fieldset className="tw-w-full tw-pt-10px">
							<Textarea id="reasonDetail" className="!tw-w-[calc(100%-2.5rem)]" placeholder="Type your reason for changing here (Optional)" onChange={ e => {
								setValues({
									...values,
									"reasonDetail": e.target.value
								})
							}}>
							</Textarea>
						</fieldset>
					</div>
					<div>
						<h3 className="tw-font-primary-bold tw-font-5 tw-text-dark tw-py-20px">
							Are you sure about cancelling <Span>this coverage?</Span>
						</h3>
						<div className="tw-flex tw-justify-between tw-space-x-60px">
							<ButtonSecondary className="tw-basis-1/2" onClick={ e => {
								setIsCanceling(false)
								e.preventDefault();
							}}>
								No
							</ButtonSecondary>
							<ButtonPrimary className="tw-basis-1/2" onClick={ e => {
								navContext.closeSideNav();
								navContext.setSideNavActions(null);
								e.preventDefault();
							}}>
								Yes, Cancel
							</ButtonPrimary>
						</div>
					</div>
				</div>
			</> : <>
				{ showDetails ? <>
					<form className="tw-py-20px tw-pr-40px tw-pl-30px tw-space-y-20px tw-w-full tw-bg-light-accent">
						<h3 className="tw-font-primary-bold tw-font-5 tw-text-dark">
							Provide Details
						</h3>
						<div className="tw-space-y-20px">
							<fieldset className="tw-space-y-15px tw-w-full">
								<InputTimes value={ navContext.values?.startTime || null } id="startTime"  label="Start Time" increments="1" min="0" max="12" placeholder="00" min2="0" max2="60" placeholder2="00" increments2="15"
									onChange={ e => {
										setStartTime(e.target.value)
										if (startTime !== e.target.value) {
											setValues({
												...values,
												[e.target.id]: e.target.value
											})
										}
									}}
								/>
								<InputTimes value={ navContext.values?.endTime || null } id="endTime" label="End Time" increments="1" min="0" max="12" placeholder="00" min2="0" max2="60" placeholder2="00" increments2="15"
									onChange={ e => {
										setEndTime(e.target.value)
										if (endTime !== e.target.value) {
											setValues({
												...values,
												[e.target.id]: e.target.value
											})
										}
									}}
								/>
							</fieldset>
							<fieldset className="tw-flex tw-flex-col">
								<Label htmlFor="frequency-demo tw-w-full">
									Staff Selection
								</Label>
								<Select id="staff" value={ staff || ''} onChange={ e => {
									setStaff(e.target.value);
									if (staff !== e.target.value) {
										var employee = directSupportTeam.find( (emp) => emp.employeeID === e.target.value);
										setValues({
											...values,
											[e.target.id]: employee.firstName + " " + employee.lastName,
											"staffID": e.target.value
										});
									}
								}}>
									{/* TODO:
									preselected  option will only be easily done if these are a list of array, @Srinivas see: https://www.pluralsight.com/guides/how-to-get-selected-value-from-a-mapped-select-input-in-react
									That's why I didn't full implement this solution but below is an easy option, it'll depend on backend
									*/}
									{ navContext && navContext.values?.staff ? <>
										<option value={ navContext.values?.staff }>
											- { navContext.values?.staff } -
										</option>
									</> : <>
										<option value="">
											- Staff -
										</option>
									</>}

									{ directSupportTeam && directSupportTeam.map( (staff, index) => {
										return <option key={`staff_${index}`} value={staff.employeeID}>
											{staff.firstName} {staff.lastName}
										</option>
									})
									}
									{/* <option value="Ali">
										Ali Rahimi
									</option>
									<option value="Elon">
										Elon Musk
									</option>
									<option value="Srinivas">
										Srinivas Rules
									</option>
									<option value="Kmack">
										Kevin Mack
									</option> */}
								</Select>
							</fieldset>
						</div>
					</form>
				</> : <>
					<div>
						<div className="tw-border-t tw-border-t-light-accent tw-pt-20px tw-pl-20px tw-pr-40px tw-pb-10px tw-space-y-20px">
							<h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em]" onClick={ e => {
								let temp = toggleInformation;
								setToggleInformation( temp = !temp )
							}}>
								<span className="tw-flex tw-space-x-5p tw-pl-20px tw-items-center tw-w-full">
									<span className="tw-flex-grow">
										Assignment Information
									</span>
									<span className="tw-ml-auto tw-flex tw-justify-start tw-items-center tw-flex-shrink-0 tw-w-20p">
										<img className="tw-w-full" src={`/images/icon-arrow-${ toggleInformation ? 'down-2' : 'up'}.svg`} alt="Icon" />
									</span>
								</span>
							</h3>
							{ assignmentsLoading ? <>
										<div className="tw-flex tw-justify-center tw-mx-4">
                                            <LoadingSpinner />
                                        </div>
									</>:<>
									{ toggleInformation ? <>
										<div>
											<CalendarHeader emptyTitle="Uncovered" />
											<Div className="">
												<DailyCalendar starting={props?.shift?.displayStartTime.replace(/\s+/g, '').toLowerCase()} ending={props?.shift?.displayEndTime.replace(/\s+/g, '').toLowerCase()}>
												{/* <OpenBlock start={ shift?.displayStartTime.replace(/\s+/g, '').toLowerCase() } end={ shift?.displayEndTime.replace(/\s+/g, '').toLowerCase() } offsetHours={ shift?.displayStartTime.replace(/\s+/g, '').toLowerCase() } onClick={ e => handleTimeblockEvent(e, '9:00am', '10:00am') } /> */}
													{/* <Timeblock start="8:30am" end="10:00am" offsetHours={ starting }  onClick={ e => handleTimeblockEvent(e, '12:00am', '9:00am', 'Kmack') }>
														Kevin, M
													</Timeblock>
													<OpenBlock start="10:00am" end="11:00am" offsetHours={ starting } onClick={ e => handleTimeblockEvent(e, '9:00am', '10:00am') } />
													<Timeblock start="11:00am" end="2:15pm" offsetHours={ starting } timestamp={ true }  onClick={ e => handleTimeblockEvent(e, '10:00am', '2:00pm', 'Kmack') }>
														Kevin, M
													</Timeblock> */}
													{ requirementEvents && requirementEvents?.map( (event, index) => {
														if (event.status === "Open"){
															return <OpenBlock key={`timeblock_${index}`} start={event.displayStartTime.replace(/\s+/g, '').toLowerCase()} end={event.displayEndTime.replace(/\s+/g, '').toLowerCase()} offsetHours={ props?.shift?.displayStartTime.replace(/\s+/g, '').toLowerCase() } onClick={ e => handleTimeblockEvent(e, event.displayStartTime, event.displayEndTime, event.providerName) } />
														} else {
															return <Timeblock key={`timeblock_${index}`} start={event.displayStartTime.replace(/\s+/g, '').toLowerCase()} end={event.displayEndTime.replace(/\s+/g, '').toLowerCase()} offsetHours={ props?.shift?.displayStartTime.replace(/\s+/g, '').toLowerCase() } timestamp={ true }  onClick={ e => handleTimeblockEvent(e, event.displayStartTime, event.displayEndTime, event.providerName) }>
																{event.providerName}
															</Timeblock>
														}
														

													})}
												</DailyCalendar>
											</Div>
										</div>
									</> : <></> }
							</>}
							
						</div>
					</div>
					<div className="tw-border-t tw-border-t-light-accent tw-pt-20px tw-flex-grow tw-w-full  tw-h-full tw-overflow-hidden">
						<div className="tw-flex tw-flex-col tw-w-full tw-h-full tw-overflow-hidden">
							<div className="tw-px-40px tw-pb-10px tw-flex-shrink-0 tw-w-full tw-space-y-10px">
								<div>
									{ showMakeComment ? <MakeComment /> : <>
										<div className="tw-w-full tw-flex tw-justify-between">
											<h3 className=" tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em]">
												Logs
											</h3>
											{/* <ButtonSecondary className="tw-min-w-100px tw-py-0 tw-max-h-30px tw-min-h-30px" childClasses="tw-max-h-30px tw-min-h-30px tw-leading-none" onClick={ e => {
												e.preventDefault();
												setShowMakeComment(true);
											}}>
												<span className="tw-text-6 tw-flex tw-items-center -tw-mt-2p">Add Notes</span>
											</ButtonSecondary> */}
										</div>
									</>}

								</div>
								<div>
									{ logsLoading ? <>
										<div className="tw-flex tw-justify-center tw-mx-4">
                                            <LoadingSpinner />
                                        </div>
									</>:<>
										<AvatarNameInlineList>
											<span className="tw-text-6 tw-text-dark-light">{requirementLogs? requirementLogs.length : "0"} Logs</span>
										</AvatarNameInlineList>
									</>}
									
								</div>
							</div>
							<div className="tw-flex-grow tw-space-y-15px tw-overflow-x-hidden tw-overflow-y-auto">
								<div className="tw-pt-20px tw-px-40px tw-pb-40px">
									{ requirementLogs && requirementLogs?.map( (log, index) => {
										return <CommentBlock key={`comment_${index}`} data={{
											user: {
												name: log?.userName,
												image: null
											},
											comment: {
												createdOn: log?.displayLoggedTime
											}
										}}>
										{log?.logMessage}
									</CommentBlock>
									})}
								{/* { [...Array(16)].map( (comment, index) => {
									return  <CommentBlock key={`comment_${index}`} data={{
											user: {
												name: "Ali Rahimi",
												image: null
											},
											comment: {
												createdOn: "March 16, 2022 | 8:00AM"
											}
										}}>
										Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facilis, eum!
									</CommentBlock>
								})} */}
								</div>
							</div>
						</div>
					</div>
				</> }
			</>}
		</div>
	</>
}

export default CoverageDetailsPanel;
