

import { Configuration, PopupRequest } from "@azure/msal-browser";

const currentRedirectUri = window.location.origin;

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "8c0291b1-bc21-495a-adb8-9a0126b2689f",
        authority: "https://login.microsoftonline.com/2c5bd741-9d32-47ed-a5ce-7b28a3fc7518",
        redirectUri: currentRedirectUri,
        postLogoutRedirectUri: currentRedirectUri
        // redirectUri: process.env.REACT_APP_REDIRECT,
        // postLogoutRedirectUri: process.env.REACT_APP_REDIRECT
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ["User.Read"]
};

export const chatRequest: PopupRequest = {
    scopes: ["Chat.Read", "Chat.ReadWrite", "Chat.ReadBasic", "ChatMessage.Read", "ChatMessage.Send"]
}

export const billingRequest: PopupRequest = {
    scopes: [""]
}

export const legacyRequest: PopupRequest = {
    scopes: ["https://ohioathome.onmicrosoft.com/0b0fd67e-ca7d-44d5-bd18-0044f64109e1/user_impersonation"]
}

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};