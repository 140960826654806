import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../../Context";
import { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { getAvailabilityPeriodByID, getAvalabilityFormResponsesExcelFile, getProviderAvailabilitiesByPeriodID } from "../../apicalls/availabilityService";
import { LoadingSpinner } from "../../components/portalComponents/loadingSpinner";
import { AvailabilityPeriod } from "../../types/availabilityPeriod";
import { ProviderAvailability } from "../../types/providerAvailability";
import { ButtonPrimary } from "../../components/buttons/Buttons";
import moment from "moment";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon, ChevronUpIcon, ClipboardDocumentIcon } from "@heroicons/react/24/solid";
import Employee from "../../types/employee";
import { getAllActiveProviders } from "../../apicalls/hrService";
import { Transition } from "@headlessui/react";

const tabs = [
    { name: 'Submitted Responses', href: '#', count: '5', current: true },
    { name: 'Pending Responses', href: '#', count: '15', current: false },
  ]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

// a simple message with grey background and icon
const EmailCopied = props => {
    return (
        <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-p-2 tw-bg-white tw-rounded-md tw-mb-2">
            <div className="tw-flex tw-flex-row tw-items-center">
                <div className="tw-flex tw-items-center tw-justify-center tw-flex-shrink-0 tw-h-6 tw-w-6 tw-rounded-full tw-bg-gray-500">
                    <ClipboardDocumentIcon className="tw-h-4 tw-w-4 tw-text-white" aria-hidden="true" />
                </div>
                <div className="tw-ml-3 tw-flex tw-flex-col">
                    <p className="tw-text-sm tw-font-medium tw-text-gray-500">Email Copied</p>
                </div>
            </div>
        </div>
    )
}

interface SubmissionEmailStatus {
    SubmissionEmail: string;
    copied: boolean;
}


const AvailabilityPeriodSubmissionsPage = props => {
    let navigate = useNavigate();
    const { periodID } = useParams();
    const navContext = useAppContext();
    const [loadingResponses, setLoadingResponses] = useState(true);

    const [reportLoading, setReportLoading] = useState(false);

    const [sortedByProvider, setSortedByProvider] = useState<string>("none");
    const [sortedByType, setSortedByType] = useState<string>("none");
    const [sortedByHours, setSortedByHours] = useState<string>("none");
    const [sortedBySubmissionDate, setSortedBySubmissionDate] = useState<string>("none");
    const [sortedByLastUpdated, setSortedByLastUpdated] = useState<string>("none");

    const [showAllEmailsCopied, setShowAllEmailsCopied] = useState<boolean>(false);
    const [submissionEmailStatus, setSubmissionEmailStatus] = useState<SubmissionEmailStatus[]>([]);


    const getFormResponsesExcel = async () => {
		setReportLoading(true);

		const excelBlob = await getAvalabilityFormResponsesExcelFile(periodID, navContext.handleAPICallError);

        var fileName = "OAH_"+ period.name?.replace(/\s+/g, '') + "_FormResponses_" + moment().format("MMDDYY") + ".xlsx";

		const url = excelBlob.blobLink;
		console.log(url);
		//window.open(url);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();

        setReportLoading(false);
	}

    const [showToast, setShowToast] = useState(true)

    const [period, setPeriod] = useState<AvailabilityPeriod>();

    const [providerAvailabilities, setProviderAvailabilities] = useState<ProviderAvailability[]>([]); 

    const [filteredProviderAvailabilities, setFilteredProviderAvailabilities] = useState<ProviderAvailability[]>([]);

    const [activeProviders, setActiveProviders] = useState<Employee[]>([]);

    const [unsubmittedProviders, setUnsubmittedProviders] = useState<Employee[]>([]);

    const [filteredUnsubmittedProviders, setFilteredUnsubmittedProviders] = useState<Employee[]>([]);

    const [currentTabs, setCurrentTabs] = useState([]);

    useLayoutEffect(() => {
        const fetchPeriod = async () => {
            setLoadingResponses(true);

            const currentPeriod = await getAvailabilityPeriodByID(periodID, navContext.handleAPICallError);

            console.log(currentPeriod);

            setPeriod(currentPeriod);

            const allProviders = await getAllActiveProviders(navContext.handleAPICallError);

            console.log(allProviders);

            setActiveProviders(allProviders);

            const availabilities = await getProviderAvailabilitiesByPeriodID(periodID, navContext.handleAPICallError);

            if(availabilities && availabilities.length > 0){

                console.log([...availabilities].sort((a, b) => a.providerName.localeCompare(b.providerName)));

                setProviderAvailabilities([...availabilities].sort((a, b) => a.providerName.localeCompare(b.providerName)));

                setFilteredProviderAvailabilities([...availabilities].sort((a, b) => a.providerName.localeCompare(b.providerName)));

                const unsubmitted = allProviders.filter(provider => !availabilities.find(availability => availability.providerID === provider.employeeID));

                console.log(unsubmitted);

                setUnsubmittedProviders([...unsubmitted].sort((a, b) => a.firstName.localeCompare(b.firstName)));

                setFilteredUnsubmittedProviders([...unsubmitted].sort((a, b) => a.firstName.localeCompare(b.firstName)));

                // add submission emails to state
                const submissionEmails = [];

                unsubmitted.forEach(provider => {
                    submissionEmails.push({
                        SubmissionEmail: provider.email,
                        copied: false
                    });
                });

                setSubmissionEmailStatus(submissionEmails);

                setCurrentTabs([
                    { name: 'Submitted Responses', href: '#', count: availabilities.length, current: true },
                    { name: 'Pending Responses', href: '#', count: unsubmitted.length, current: false },
                ]);
            } else {
                setProviderAvailabilities([]);
                setFilteredProviderAvailabilities([]);

                setUnsubmittedProviders(allProviders);
                setFilteredUnsubmittedProviders(allProviders);

                // add submission emails to state
                const submissionEmails = [];

                allProviders.forEach(provider => {
                    submissionEmails.push({
                        SubmissionEmail: provider.email,
                        copied: false
                    });
                });

                setSubmissionEmailStatus(submissionEmails);

                setCurrentTabs([
                    { name: 'Submitted Responses', href: '#', count: 0, current: true },
                    { name: 'Pending Responses', href: '#', count: allProviders.length, current: false },
                ]);
            }

            setLoadingResponses(false);
        }

        fetchPeriod();
    }, []);

    const goToResponseDetails = (availability: ProviderAvailability) => {
        navigate(`/availability/submissions/${period.periodID}/submissions/${availability.providerAvailabilityID}`);
    }

    const NavigateToTab = (tab) => {
        setCurrentTabs(currentTabs.map((item) => {
            if (item.name === tab.name) {
                return {
                    ...item,
                    current: true
                }
            } else {
                return {
                    ...item,
                    current: false
                }
            }
        }));
    }

    const copyEmployeeEmailToClipboard = (employee: Employee) => {
        if(employee.personalEmail && employee.personalEmail !== ""){
            navigator.clipboard.writeText(employee.personalEmail + ";" + employee.email);
        } else {
            navigator.clipboard.writeText(employee.email);
        }


        // navContext.setModalMessageHeader("Email Copied");
        // navContext.setModalMessageBody("The email address has been copied to your clipboard.");
        // navContext.setShowSuccessModal(true);

        // update state
        const updatedSubmissionEmails = [...submissionEmailStatus];

        const index = updatedSubmissionEmails.findIndex(submissionEmail => submissionEmail.SubmissionEmail === employee.email);

        if(index !== -1){
            updatedSubmissionEmails[index].copied = true;
        }

        setSubmissionEmailStatus(updatedSubmissionEmails);

        // show toast for 2 seconds
        setTimeout(() => {
            const updatedSubmissionEmails = [...submissionEmailStatus];

            const index = updatedSubmissionEmails.findIndex(submissionEmail => submissionEmail.SubmissionEmail === employee.email);

            if(index !== -1){
                updatedSubmissionEmails[index].copied = false;
            }

            setSubmissionEmailStatus(updatedSubmissionEmails);
        }, 2000);
    }

    const copyAllUnsubmittedEmailsToClipboard = () => {
        let emails = "";

        unsubmittedProviders.forEach(provider => {
            if(provider.personalEmail && provider.personalEmail !== ""){
                emails += provider.personalEmail + ";";
            }
            emails += provider.email + ";";
        });

        navigator.clipboard.writeText(emails);

        // show all emails copied for 2 seconds
        setShowAllEmailsCopied(true);

        setTimeout(() => {
            setShowAllEmailsCopied(false);
        }, 2000);

        // navContext.setModalMessageHeader("Emails Copied");
        // navContext.setModalMessageBody("The email addresses have been copied to your clipboard.");
        // navContext.setShowSuccessModal(true);
    }

    const toggleSortByProvider = () => {
        if(sortedByProvider === "none" || sortedByProvider === "descending"){
            setSortedByProvider("ascending");
            setSortedByType("none");
            setSortedByHours("none");
            setSortedBySubmissionDate("none");
            setSortedByLastUpdated("none");
            setFilteredProviderAvailabilities([...filteredProviderAvailabilities].sort((a, b) => a.providerName.localeCompare(b.providerName)));
        } else {
            setSortedByProvider("descending");
            setSortedByType("none");
            setSortedByHours("none");
            setSortedBySubmissionDate("none");
            setSortedByLastUpdated("none");
            setFilteredProviderAvailabilities([...filteredProviderAvailabilities].sort((a, b) => b.providerName.localeCompare(a.providerName)));
        }
    }

    const toggleSortByType = () => {
        if(sortedByType === "none" || sortedByType === "descending"){
            setSortedByType("ascending");
            setSortedByProvider("none");
            setSortedByHours("none");
            setSortedBySubmissionDate("none");
            setSortedByLastUpdated("none");
            // order by DS, then RS
            setFilteredProviderAvailabilities([...filteredProviderAvailabilities].sort((a, b) => {
                if(a.directSupportShifts && a.remoteSupportShifts && !b.directSupportShifts && !b.remoteSupportShifts){
                    return -1;
                } else if(!a.directSupportShifts && !a.remoteSupportShifts && b.directSupportShifts && b.remoteSupportShifts){
                    return 1;
                } else if(a.directSupportShifts && !b.directSupportShifts){
                    return -1;
                } else if(!a.directSupportShifts && b.directSupportShifts){
                    return 1;
                } else if(a.remoteSupportShifts && !b.remoteSupportShifts){
                    return -1;
                } else if(!a.remoteSupportShifts && b.remoteSupportShifts){
                    return 1;
                } else {
                    return 0;
                }
            }));
        } else {
            setSortedByType("descending");
            setSortedByProvider("none");
            setSortedByHours("none");
            setSortedBySubmissionDate("none");
            setSortedByLastUpdated("none");
            // order by RS, then DS
            setFilteredProviderAvailabilities([...filteredProviderAvailabilities].sort((a, b) => {
                if(a.directSupportShifts && a.remoteSupportShifts && !b.directSupportShifts && !b.remoteSupportShifts){
                    return 1;
                } else if(!a.directSupportShifts && !a.remoteSupportShifts && b.directSupportShifts && b.remoteSupportShifts){
                    return -1;
                } else if(a.directSupportShifts && !b.directSupportShifts){
                    return 1;
                } else if(!a.directSupportShifts && b.directSupportShifts){
                    return -1;
                } else if(a.remoteSupportShifts && !b.remoteSupportShifts){
                    return 1;
                } else if(!a.remoteSupportShifts && b.remoteSupportShifts){
                    return -1;
                } else {
                    return 0;
                }
            }));
        }
    }

    const toggleSortByHours = () => {
        if(sortedByHours === "none" || sortedByHours === "descending"){
            setSortedByHours("ascending");
            setSortedByProvider("none");
            setSortedByType("none");
            setSortedBySubmissionDate("none");
            setSortedByLastUpdated("none");
            setFilteredProviderAvailabilities([...filteredProviderAvailabilities].sort((a, b) => a.weeklyHours.desired - b.weeklyHours.desired));
        } else {
            setSortedByHours("descending");
            setSortedByProvider("none");
            setSortedByType("none");
            setSortedBySubmissionDate("none");
            setSortedByLastUpdated("none");
            setFilteredProviderAvailabilities([...filteredProviderAvailabilities].sort((a, b) => b.weeklyHours.desired - a.weeklyHours.desired));
        }
    }

    const toggleSortBySubmissionDate = () => {
        if(sortedBySubmissionDate === "none" || sortedBySubmissionDate === "descending"){
            setSortedBySubmissionDate("ascending");
            setSortedByHours("none");
            setSortedByProvider("none");
            setSortedByType("none");
            setSortedByLastUpdated("none");
            setFilteredProviderAvailabilities([...filteredProviderAvailabilities].sort((a, b) => moment.utc(a.submissionDate).diff(moment.utc(b.submissionDate))));
        } else {
            setSortedBySubmissionDate("descending");
            setSortedByHours("none");
            setSortedByProvider("none");
            setSortedByType("none");
            setSortedByLastUpdated("none");
            setFilteredProviderAvailabilities([...filteredProviderAvailabilities].sort((a, b) => moment.utc(b.submissionDate).diff(moment.utc(a.submissionDate))));
        }
    }

    const toggleSortByLastUpdated = () => {
        if(sortedByLastUpdated === "none" || sortedByLastUpdated === "descending"){
            setSortedByLastUpdated("ascending");
            setSortedBySubmissionDate("none");
            setSortedByHours("none");
            setSortedByProvider("none");
            setSortedByType("none");
            setFilteredProviderAvailabilities([...filteredProviderAvailabilities].sort((a, b) => moment.utc(a.lastUpdated).diff(moment.utc(b.lastUpdated))));
        } else {
            setSortedByLastUpdated("descending");
            setSortedBySubmissionDate("none");
            setSortedByHours("none");
            setSortedByProvider("none");
            setSortedByType("none");
            setFilteredProviderAvailabilities([...filteredProviderAvailabilities].sort((a, b) => moment.utc(b.lastUpdated).diff(moment.utc(a.lastUpdated))));
        }
    }

    return (<>
        <div className="tw-px-xl tw-py-sm">
        

                <div className="tw-border-b tw-border-gray-800 tw-pb-5">
                    <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Availability Submissions {period ? " - " + period.name: ""}</h2>
                </div>

                {loadingResponses ? <div className="tw-flex tw-justify-center tw-mx-4 tw-mt-md">
                <LoadingSpinner />
                </div>:

                <>
                <div className="tw-pb-5">
                <div className="hidden sm:block">
                    <div className="tw-border-b tw-border-gray-200">
                    <nav className="-tw-mb-px tw-flex tw-space-x-8" aria-label="Tabs">
                        {currentTabs.map((tab) => (
                        <span
                            key={tab.name}
                            className={classNames(
                            tab.current
                                ? 'tw-border-primary tw-text-primary'
                                : 'tw-border-transparent tw-text-gray-500 hover:tw-border-gray-200 hover:tw-text-gray-700 tw-cursor-pointer',
                            'tw-flex tw-whitespace-nowrap tw-border-b-2 tw-py-4 tw-px-1 tw-text-sm tw-font-medium'
                            )}
                            aria-current={tab.current ? 'page' : undefined}
                            onClick={() => NavigateToTab(tab)}
                        >
                            {tab.name}
                            <span
                                className={classNames(
                                tab.current ? 'tw-bg-primary-bg tw-text-primary' : 'tw-bg-gray-100 tw-text-gray-900',
                                'tw-ml-3 tw-hidden tw-rounded-full tw-py-0.5 tw-px-2.5 tw-text-xs tw-font-medium md:tw-inline-block'
                                )}
                            >
                                {tab.count}
                            </span>
                        </span>
                        ))}
                    </nav>
                    </div>
                </div>
                {currentTabs[0].current && <>
                <div className="tw-flex tw-mt-5">
                <div className="tw-relative tw-flex-grow tw-rounded-md tw-shadow-sm tw-mx-6">
                                                <input
                                                type="text"
                                                name="search-client"
                                                id="search-client"
                                                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-pl-4 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 placeholder:tw-pl-3 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                                                placeholder="Search by Provider Name..." onChange={ e => {

                                                    console.log(e?.target.value || "nope");
            
                                                    if(e?.target.value){
                                                        const searchText = e?.target.value.toString().toLowerCase();
                                                        const filtered = providerAvailabilities.filter(element => {
                                                            
                                                            // 👇️ using AND (&&) operator
                                                            return element.providerName.toLowerCase().includes(searchText);
                                                        });
            
                                                        console.log(filtered)
            
                                                        setFilteredProviderAvailabilities(filtered);
                                                    } else {
                                                        setFilteredProviderAvailabilities(providerAvailabilities);
                                                    }
                                                }}
                                                />
                                                <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3">
                                                    <MagnifyingGlassIcon className="tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
                                                </div>
                                            </div>

                                {reportLoading ? <div className="tw-flex tw-justify-center tw-mx-4">
                                <LoadingSpinner />

                                </div> :
                                     <ButtonPrimary onClick={() => getFormResponsesExcel()}>
                                            Export
                                    </ButtonPrimary>
                                }
                               
                </div>
                                
                                            
                <ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-10px tw-leading-tight tw-mt-15px">
                        <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-border-b tw-pb-2">
                            <div className="tw-flex tw-w-full tw-flex-row tw-space-x-1">
                                    <span className="tw-w-180px tw-flex tw-cursor-pointer" onClick={toggleSortByProvider}>
                                        <span className="tw-text-dark tw-font-semibold">Provider</span>
                                        {sortedByProvider === "ascending" && <span className="tw-ml-2 tw-flex-none tw-rounded tw-bg-gray-100 tw-text-gray-900 group-hover:tw-bg-gray-200">
                                            <ChevronDownIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                        </span>}
                                        {sortedByProvider === "descending" && <span className="tw-ml-2 tw-flex-none tw-rounded tw-bg-gray-100 tw-text-gray-900 group-hover:tw-bg-gray-200">
                                            <ChevronUpIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                        </span>}
                                    </span>
                                    <span className="tw-w-140px tw-flex tw-cursor-pointer" onClick={toggleSortByType}>
                                        <span className="tw-text-dark tw-font-semibold">Support Types</span>
                                        {sortedByType === "ascending" && <span className="tw-ml-2 tw-flex-none tw-rounded tw-bg-gray-100 tw-text-gray-900 group-hover:tw-bg-gray-200">
                                            <ChevronDownIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                        </span>}
                                        {sortedByType === "descending" && <span className="tw-ml-2 tw-flex-none tw-rounded tw-bg-gray-100 tw-text-gray-900 group-hover:tw-bg-gray-200">
                                            <ChevronUpIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                        </span>}
                                    </span>
                                    <span className="tw-w-180px tw-flex tw-cursor-pointer" onClick={toggleSortByHours}>
                                        <span className="tw-text-dark tw-font-semibold">Desired Hours</span>
                                        {sortedByHours === "ascending" && <span className="tw-ml-2 tw-flex-none tw-rounded tw-bg-gray-100 tw-text-gray-900 group-hover:tw-bg-gray-200">
                                            <ChevronDownIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                        </span>}
                                        {sortedByHours === "descending" && <span className="tw-ml-2 tw-flex-none tw-rounded tw-bg-gray-100 tw-text-gray-900 group-hover:tw-bg-gray-200">
                                            <ChevronUpIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                        </span>}
                                    </span>
                                    <span className="tw-w-180px tw-flex tw-cursor-pointer" onClick={toggleSortBySubmissionDate}>
                                        <span className="tw-text-dark tw-font-semibold">Submission Date</span>
                                        {sortedBySubmissionDate === "ascending" && <span className="tw-ml-2 tw-flex-none tw-rounded tw-bg-gray-100 tw-text-gray-900 group-hover:tw-bg-gray-200">
                                            <ChevronDownIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                        </span>}
                                        {sortedBySubmissionDate === "descending" && <span className="tw-ml-2 tw-flex-none tw-rounded tw-bg-gray-100 tw-text-gray-900 group-hover:tw-bg-gray-200">
                                            <ChevronUpIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                        </span>}
                                    </span>
                                    <span className="tw-w-180px tw-flex tw-cursor-pointer" onClick={toggleSortByLastUpdated}>
                                        <span className="tw-text-dark tw-font-semibold">Last Updated</span>
                                        {sortedByLastUpdated === "ascending" && <span className="tw-ml-2 tw-flex-none tw-rounded tw-bg-gray-100 tw-text-gray-900 group-hover:tw-bg-gray-200">
                                            <ChevronDownIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                        </span>}
                                        {sortedByLastUpdated === "descending" && <span className="tw-ml-2 tw-flex-none tw-rounded tw-bg-gray-100 tw-text-gray-900 group-hover:tw-bg-gray-200">
                                            <ChevronUpIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                        </span>}
                                    </span>
                            </div>
                        </li>
                
                {filteredProviderAvailabilities.map((availability, index) => {
                    return (
                        <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-border-b">
                            <div className="tw-flex tw-w-full tw-flex-row tw-space-x-1">
                                    <span className="tw-w-180px">
                                        <span className="tw-text-dark">{availability.providerName}</span>
                                    </span>
                                    <span className="tw-w-140px">
                                        {availability.directSupportShifts && availability.remoteSupportShifts && <span className="tw-text-dark">DS & RS</span>}
                                        {availability.directSupportShifts && !availability.remoteSupportShifts && <span className="tw-text-dark">DS</span>}
                                        {!availability.directSupportShifts && availability.remoteSupportShifts && <span className="tw-text-dark">RS</span>}
                                        {!availability.directSupportShifts && !availability.remoteSupportShifts && <span className="tw-text-dark">N/A</span>}
                                    </span>
                                    <span className="tw-w-180px">
                                        <span className="tw-text-dark">{availability.weeklyHours.desired}</span>
                                    </span>
                                    <span className="tw-w-180px">
                                        <span className="tw-text-dark">{moment.utc(availability.submissionDate).format('MMM Do YYYY')}</span>
                                    </span>
                                    <span className="tw-w-180px">
                                        <span className="tw-text-dark">{moment.utc(availability.lastUpdated).tz('America/New_York').format('MMM Do YYYY h:mm A')}</span>
                                    </span>
                                    <ButtonPrimary className="tw-mb-sm" onClick={() => goToResponseDetails(availability)}>
                                        View Details
                                    </ButtonPrimary>
                            </div>
                        </li>
                    )
                })}
                </ul>
                </>}

                {currentTabs[1].current && <>
                    <div className="tw-flex tw-mt-5">
                            <div className="tw-relative tw-flex-grow tw-rounded-md tw-shadow-sm tw-mx-6">
                                                <input
                                                type="text"
                                                name="search-client"
                                                id="search-client"
                                                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-pl-4 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 placeholder:tw-pl-3 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                                                placeholder="Search by Provider Name..." onChange={ e => {

                                                    console.log(e?.target.value || "nope");
            
                                                    if(e?.target.value){
                                                        const searchText = e?.target.value.toString().toLowerCase();
                                                        const filtered = unsubmittedProviders.filter(element => {
                                                            
                                                            // 👇️ using AND (&&) operator
                                                            return element.firstName.toLowerCase().includes(searchText) || element.lastName.toLowerCase().includes(searchText);
                                                        });
            
                                                        console.log(filtered)
            
                                                        setFilteredUnsubmittedProviders(filtered);
                                                    } else {
                                                        setFilteredUnsubmittedProviders(unsubmittedProviders);
                                                    }
                                                }}
                                                />
                                                <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3">
                                                    <MagnifyingGlassIcon className="tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
                                                </div>
                    </div>
                            {showAllEmailsCopied ? <>
                                <EmailCopied />
                            </>:<>
                                <ButtonPrimary onClick={() => copyAllUnsubmittedEmailsToClipboard()}>
                                        Copy All Emails
                                </ButtonPrimary>
                            </>}
                                
                                
                    </div>
                    

                    <ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-10px tw-leading-tight tw-mt-15px">
                        <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-border-b tw-pb-2">
                            <div className="tw-flex tw-w-full tw-flex-row tw-space-x-1">
                                    <span className="tw-w-180px">
                                        <span className="tw-text-dark tw-font-semibold">Provider</span>
                                    </span>
                                    <span className="tw-w-180px">
                                        <span className="tw-text-dark tw-font-semibold">Support Types</span>
                                    </span>
                                    <span className="tw-w-180px">
                                        <span className="tw-text-dark tw-font-semibold">Phone Number</span>
                                    </span>
                                    <span className="tw-flex-grow">
                                        <span className="tw-text-dark tw-font-semibold">Email</span>
                                    </span>
                            </div>
                        </li>
                
                {filteredUnsubmittedProviders.map((provider, index) => {
                    return (
                        <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-border-b">
                            <div className="tw-flex tw-w-full tw-flex-row tw-space-x-1">
                                    <span className="tw-w-180px">
                                        <span className="tw-text-dark">{provider.firstName} {provider.lastName}</span>
                                    </span>
                                    <span className="tw-w-180px">
                                        {provider.directCareProvider && provider.remoteSupportProvider && <span className="tw-text-dark">DS & RS</span>}
                                        {provider.directCareProvider && !provider.remoteSupportProvider  && <span className="tw-text-dark">DS</span>}
                                        {!provider.directCareProvider && provider.remoteSupportProvider  && <span className="tw-text-dark">RS</span>}
                                    </span>
                                    <span className="tw-w-180px">
                                        <span className="tw-text-dark">{provider.phoneNumber}</span>
                                    </span>
                                    <span className="tw-flex-grow">
                                        <span className="tw-text-dark">{provider.email}</span>
                                    </span>
                                    {submissionEmailStatus.find(submissionEmail => submissionEmail.SubmissionEmail === provider.email)?.copied ? <EmailCopied></EmailCopied> : <ButtonPrimary className="tw-mb-sm" onClick={() => copyEmployeeEmailToClipboard(provider)}> Copy Email </ButtonPrimary>} 
                                    {/* <ButtonPrimary className="tw-mb-sm" onClick={() => copyEmployeeEmailToClipboard(provider)}>
                                        Copy Email
                                    </ButtonPrimary> */}
                            </div>
                        </li>
                    )
                })}
                </ul>
                </>}
                </div>
                </>}
        </div>
    </>);
}

export default AvailabilityPeriodSubmissionsPage;