import { AlertCircle, PlayCircle, PauseCircle, Plus, X, Bell, Info, ChevronRight, Check, Clock } from 'lucide-react'
import { useCallback, useEffect, useRef, useState } from 'react';
import ToastManager from '../../components/utilities/ToastManager';

interface Message {
    id: string;
    body: any;
    enqueuedTime: Date;
}

const MonitoringDashboardPage = props => {
    const toastManagerRef = useRef<{ addToast: (message: string) => void }>(null);

    const connectionString = process.env.REACT_APP_AZURE_CONNECTION_STRING;
    const queueName = process.env.REACT_APP_AZURE_QUEUE_NAME;
    const pollInterval = 5000;

    const simulateMessage = () => {
        const randomMessage = `Message received at ${new Date().toLocaleTimeString()}`;
        toastManagerRef.current?.addToast(randomMessage);
    };

    const [messages, setMessages] = useState<Message[]>([]);
    const [isConnected, setIsConnected] = useState(false);
    const [error, setError] = useState<string>('');

    // Extract required information from connection string
    const getConnectionInfo = (connectionString: string) => {
        const params = new Map(
        connectionString.split(';')
            .map(pair => pair.split('=') as [string, string])
        );

        const endpoint = params.get('Endpoint')?.replace('sb://', 'https://');
        const keyName = params.get('SharedAccessKeyName');
        const accessKey = params.get('SharedAccessKey');

        if (!endpoint || !keyName || !accessKey) {
        throw new Error('Invalid connection string format');
        }

        return { endpoint, keyName, accessKey };
    };

    // Generate SAS token for authentication
    const generateSasToken = (uri: string, keyName: string, accessKey: string) => {
        const encoder = new TextEncoder();
        const now = Math.floor(Date.now() / 1000);
        const week = 60 * 60 * 24 * 7;
        const expiry = now + week;
        const stringToSign = encodeURIComponent(uri) + '\n' + expiry;
        
        // Note: In a production environment, this should be done server-side
        // This is a simplified version for demonstration
        const signature = btoa(stringToSign);
        
        return `SharedAccessSignature sr=${encodeURIComponent(uri)}&sig=${encodeURIComponent(signature)}&se=${expiry}&skn=${keyName}`;
    };

    // Receive messages from the queue
    const receiveMessages = useCallback(async () => {
        try {
            const { endpoint, keyName, accessKey } = getConnectionInfo(connectionString);
        const queueUrl = `${endpoint}${queueName}/messages/head`;
        const sasToken = generateSasToken(queueUrl, keyName, accessKey);

        const response = await fetch(queueUrl, {
            method: 'POST',
            headers: {
            'Authorization': sasToken,
            'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const message = await response.json();
        
        // Process the received message
        if (message) {
            const newMessage: Message = {
            id: message.MessageId || Date.now().toString(),
            body: message.MessageText,
            enqueuedTime: new Date(message.EnqueuedTimeUtc)
            };

            toastManagerRef.current?.addToast(newMessage.body);

            setMessages(prevMessages => [...prevMessages, newMessage]);
            setIsConnected(true);
            setError('');

            // Complete the message (delete from queue)
            const lockToken = response.headers.get('lockToken');
            if (lockToken) {
            await fetch(`${queueUrl}/${message.MessageId}/${lockToken}`, {
                method: 'DELETE',
                headers: {
                'Authorization': sasToken
                }
            });
            }
        }
        } catch (err) {
        console.error('Failed to receive message:', err);
        setError(`Failed to receive message: ${err instanceof Error ? err.message : 'Unknown error'}`);
        setIsConnected(false);
        }
    }, [connectionString, queueName]);

    useEffect(() => {
        // Start polling for messages
        const intervalId = setInterval(receiveMessages, pollInterval);
    
        // Initial connection
        receiveMessages();
    
        return () => {
          clearInterval(intervalId);
        };
    }, [receiveMessages, pollInterval]);

    const notifications = [
        { id: 1, type: 'Session Not Started', message: 'Remote support session for John Doe has not started.', time: '10:00 AM', urgency: 'medium', clientName: 'John Doe' },
        { id: 2, type: 'Session Started', message: 'Remote support session for Jane Smith has started.', time: '09:30 AM', urgency: 'low', clientName: 'Jane Smith' },
        { id: 3, type: 'Incident Report', message: 'An incident report has been created for Alice Johnson.', time: '11:15 AM', urgency: 'high', clientName: 'Alice Johnson' },
        { id: 4, type: 'Coverage Requirement Added', message: 'A coverage requirement has been added for Bob Brown.', time: '12:45 PM', urgency: 'medium', clientName: 'Bob Brown' },
        { id: 5, type: 'Coverage Requirement Cancelled', message: 'A coverage requirement has been cancelled for Carol Williams.', time: '02:20 PM', urgency: 'low', clientName: 'Carol Williams' },
        { id: 6, type: 'Support Ticket', message: 'A support ticket has been created for David Lee.', time: '03:10 PM', urgency: 'medium', clientName: 'David Lee' },
    ];

    const admins = [
        { id: 1, name: 'Admin Smith' },
        { id: 2, name: 'Admin Johnson' },
        { id: 3, name: 'Admin Williams' },
        { id: 4, name: 'Admin Brown' },
    ];

    const directSupport = [
        { id: 1, type: 'Shift Not Started', message: 'Jennifer Westenberger has not checked in to their direct support shift with Tistant M.', description: 'Jennifer Westenberger has not started their direct support with Tistant M starting at 09:00 AM. Shift Date: 9/14/2024.', urgency: 'medium', details: 'PCA Phone Number: 614-370-3529' },
        { id: 2, type: 'Shift Started', message: 'Sankay Jones checked in to their direct support shift with Yolanda J.', description: 'Sankay Jones checked in to their direct support shift with Yolanda J. starting at 09:00 AM. Shift Date: 9/14/2024', urgency: 'low', details: '' },
        { id: 3, type: 'Open Shift', message: 'Samantha P has an open shift at 10:00 AM', description: 'Samantha P has an open direct support shift occuring on 8/16/2024 at 10:00 AM.', urgency: 'high', details: '' },
        { id: 4, type: 'Time Off Request', message: 'New Time-Off Request Submitted', description: 'Joy Ayinde has submitted a new time-off request from Aug 16th to 18th 2024. This will affect the session schedule for the next few days', urgency: 'medium', details: 'DS Shifts Affected: 24' },
        { id: 5, type: 'Incident Report', message: 'UI/MUI submitted for Chance T.', description: 'Maxx Williams submitted an UI/MUI for Chance T. at 05:27 PM', urgency: 'high', details: 'Provider Contact Number: 614-370-3529' },
        { id: 6, type: 'Shift Claimed', message: 'Sankay Jones has claimed an open shift with Yolanda J.', description: 'Sankay Jones has claimed an open shift with Yolanda J. starting at 09:00 AM. Shift Date: 9/14/2024', urgency: 'low', details: '' }
    ];

    const [activeTab, setActiveTab] = useState('remote')
    const [newNotifications, setNewNotifications] = useState(3)
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState(null);
    const [selectedAdmins, setSelectedAdmins] = useState([]);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [isAdminDropdownOpen, setIsAdminDropdownOpen] = useState(false);

    const getUrgencyColor = (urgency: string) => {
        switch (urgency) {
            case 'high':
                return 'tw-bg-red-100 tw-text-red-800'
            case 'medium':
                return 'tw-bg-yellow-100 tw-text-yellow-800'
            case 'low':
                return 'tw-bg-green-100 tw-text-green-800'
            default:
                return 'tw-bg-gray-100 tw-text-gray-800'
        }
    }

    const getDirectSupportTypeIcon = (type: string) => {
        switch (type) {
            case 'Shift Not Started':
                return <AlertCircle className="tw-mr-2" size={16} />
            case 'Shift Started':
                return <Check className="tw-mr-2" size={16} />
            case 'Open Shift':
                return <Clock className="tw-mr-2" size={16} />
            case 'Incident Report':
                return <AlertCircle className="tw-mr-2" size={16} />
            case 'Time Off Request':
                return <Clock className="tw-mr-2" size={16} />
            case 'Shift Claimed':
                return <Check className="tw-mr-2" size={16} />
            default:
                return null
        }
    }

    const getDirectSupportTypeColor = (type: string) => {
        switch (type) {
            case 'Shift Not Started':
                return 'tw-text-yellow-500'
            case 'Shift Started':
                return 'tw-text-green-500'
            case 'Open Shift':
                return 'tw-text-red-500'
            case 'Incident Report':
                return 'tw-text-red-500'
            case 'Time Off Request':
                return 'tw-text-blue-500'
            case 'Shift Claimed':
                return 'tw-text-green-500'
            default:
                return 'tw-text-gray-500'
        }
    }

    const getTypeIcon = (type: string) => {
        switch (type) {
            case 'Session Not Started':
                return <PauseCircle className="tw-mr-2" size={16} />
            case 'Session Started':
                return <PlayCircle className="tw-mr-2" size={16} />
            case 'Incident Report':
                return <AlertCircle className="tw-mr-2" size={16} />
            case 'Coverage Requirement Added':
                return <Plus className="tw-mr-2" size={16} />
            case 'Coverage Requirement Cancelled':
                return <X className="tw-mr-2" size={16} />
            case 'Support Ticket':
                return <Info className="tw-mr-2" size={16} />
            default:
                return null
        }
    }

    const getTypeColor = (type: string) => {
        switch (type) {
            case 'Session Not Started':
                return 'tw-text-yellow-500'
            case 'Session Started':
                return 'tw-text-green-500'
            case 'Incident Report':
                return 'tw-text-red-500'
            case 'Coverage Requirement Added':
                return 'tw-text-blue-500'
            case 'Coverage Requirement Cancelled':
                return 'tw-text-purple-500'
            case 'Support Ticket':
                return 'tw-text-indigo-500'
            default:
                return 'tw-text-gray-500'
        }
    }

    const handleNotifyClick = (notification) => {
        setSelectedNotification(notification);
        setIsPanelOpen(true);
        setNotificationMessage(''); // Reset message
        setSelectedAdmins([]); // Reset selected admins
    };

    return (<>
        <div className="tw-px-xl tw-pt-xl">
            <div className="tw-pb-5">
                <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Monitoring Dashboard</h2>
            </div>

            <div className="tw-border-b tw-border-gray-200 tw-mb-6">
                <nav className="tw-flex tw-space-x-4" aria-label="Tabs">
                    {[
                        { id: 'remote', name: 'Remote Support' },
                        { id: 'direct', name: 'Direct Support' },
                        { id: 'admin', name: 'Administration' },
                    ].map((tab) => (
                        <button
                            key={tab.id}
                            onClick={() => setActiveTab(tab.id)}
                            className={`tw-relative tw-px-3 tw-py-2 tw-text-sm tw-font-medium ${
                                activeTab === tab.id
                                    ? 'tw-border-b-2 tw-border-[#103f4c] tw-text-[#103f4c]'
                                    : 'tw-text-gray-500 hover:tw-text-gray-700'
                            }`}
                        >
                            {tab.name}
                            {tab.id === 'remote' && newNotifications > 0 && (
                                <span className="tw-absolute tw-top-0 tw-right-0">
                                    <span className="tw-h-2.5 tw-w-2.5 tw-rounded-full tw-bg-red-500 tw-block"></span>
                                </span>
                            )}
                        </button>
                    ))}
                </nav>
            </div>

            {/* Conditionally render content based on active tab */}
            {activeTab === 'remote' && (
                <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-1 lg:tw-grid-cols-1 tw-gap-4">
                {notifications.map((notification) => (
                    <div key={notification.id} className="tw-bg-white tw-rounded-lg tw-shadow tw-p-4 tw-w-3/4">
                        <div className="tw-flex tw-items-center tw-justify-between tw-space-y-0 tw-pb-2">
                            <h3 className={`tw-text-sm tw-font-medium tw-flex tw-items-center ${getTypeColor(notification.type)}`}>
                                {getTypeIcon(notification.type)}
                                {notification.type}
                            </h3>
                            <span className={`tw-px-2 tw-py-1 tw-rounded-full tw-text-xs tw-font-medium ${getUrgencyColor(notification.urgency)}`}>
                                {notification.urgency}
                            </span>
                        </div>
                        <div className="tw-mt-2">
                            <p className="tw-text-xs tw-text-gray-500">Client: {notification.clientName}</p>
                            <p className="tw-text-xs tw-text-gray-500">Time: {notification.time}</p>
                            <p className="tw-mt-2 tw-text-sm">{notification.message}</p>
                            <button 
                                className="tw-mt-4 tw-w-full tw-bg-[#103f4c] tw-text-white tw-py-2 tw-px-4 tw-rounded hover:tw-bg-[#185c70]"
                                onClick={() => handleNotifyClick(notification)}
                            >
                                Notify
                            </button>
                        </div>
                    </div>
                    ))}
                </div>
            )}
            {activeTab === 'direct' && (
                <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-1 lg:tw-grid-cols-1 tw-gap-4">
                    {directSupport.map((notification) => (
                        <div key={notification.id} className="tw-bg-white tw-rounded-lg tw-shadow tw-p-4 tw-w-3/4">
                        <div className="tw-flex tw-items-center tw-justify-between tw-space-y-0 tw-pb-2">
                            <h3 className={`tw-text-sm tw-font-medium tw-flex tw-items-center ${getDirectSupportTypeColor(notification.type)}`}>
                                {getDirectSupportTypeIcon(notification.type)}
                                {notification.message}
                            </h3>
                            <span className={`tw-px-2 tw-py-1 tw-rounded-full tw-text-xs tw-font-medium ${getUrgencyColor(notification.urgency)}`}>
                                {notification.urgency}
                            </span>
                        </div>
                        <div className="tw-mt-2">
                            {/* <p className="tw-text-xs tw-text-gray-500">{notification.message}</p> */}
                            <p className="tw-text-sm tw-text-gray-500">{notification.description}</p>
                            {notification.details && <p className="tw-mt-2 tw-text-sm">{notification.details}</p>}
                            <button 
                                className="tw-mt-4 tw-w-full tw-bg-[#103f4c] tw-text-white tw-py-2 tw-px-4 tw-rounded hover:tw-bg-[#185c70]"
                                onClick={() => handleNotifyClick(notification)}
                            >
                                Notify
                            </button>
                        </div>
                    </div>
                    ))}
                </div>
            )}
            {activeTab === 'admin' && (
                <div className="tw-text-center tw-text-gray-500 tw-py-8">
                    Administration content coming soon
                </div>
            )}

            {/* Notification Panel */}
            {isPanelOpen && selectedNotification && (
                <div className="tw-fixed tw-inset-y-0 tw-right-0 tw-w-96 tw-bg-white tw-shadow-xl tw-transform tw-transition-transform tw-duration-300 tw-ease-in-out tw-z-50">
                    <div className="tw-h-full tw-flex tw-flex-col tw-divide-y">
                        {/* Header */}
                        <div className="tw-px-6 tw-py-4 tw-flex tw-justify-between tw-items-center">
                            <h3 className="tw-text-lg tw-font-medium">Notify Admins</h3>
                            <button 
                                onClick={() => setIsPanelOpen(false)}
                                className="tw-text-gray-400 hover:tw-text-gray-500"
                            >
                                <X size={20} />
                            </button>
                        </div>

                        {/* Content */}
                        <div className="tw-flex-1 tw-overflow-y-auto tw-p-6">
                            {/* Notification Details */}
                            <div className="tw-mb-6">
                                <h4 className="tw-text-sm tw-font-medium tw-text-gray-500 tw-mb-2">Notification Details</h4>
                                <div className="tw-bg-gray-50 tw-p-4 tw-rounded">
                                    <p className="tw-text-sm"><strong>Type:</strong> {selectedNotification.type}</p>
                                    <p className="tw-text-sm"><strong>Client:</strong> {selectedNotification.clientName}</p>
                                    <p className="tw-text-sm"><strong>Message:</strong> {selectedNotification.message}</p>
                                </div>
                            </div>

                            {/* Admin Selection */}
                            <div className="tw-mb-6">
                                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-500 tw-mb-2">
                                    Select Admins to Notify
                                </label>
                                <div className="tw-relative">
                                    <button
                                        type="button"
                                        className="tw-w-full tw-border tw-border-gray-300 tw-rounded tw-p-2 tw-text-left tw-flex tw-justify-between tw-items-center"
                                        onClick={() => setIsAdminDropdownOpen(!isAdminDropdownOpen)}
                                    >
                                        <span className="tw-text-sm">Select admins...</span>
                                        <ChevronRight className={`tw-transform ${isAdminDropdownOpen ? 'tw-rotate-90' : ''}`} />
                                    </button>
                                    
                                    {isAdminDropdownOpen && (
                                        <div className="tw-absolute tw-z-10 tw-w-full tw-mt-1 tw-bg-white tw-border tw-border-gray-300 tw-rounded tw-shadow-lg">
                                            <div className="tw-max-h-48 tw-overflow-y-auto">
                                                {admins.map(admin => (
                                                    <label
                                                        key={admin.id}
                                                        className="tw-flex tw-items-center tw-px-4 tw-py-2 hover:tw-bg-gray-100 tw-cursor-pointer"
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            className="tw-mr-3"
                                                            checked={selectedAdmins.includes(admin.id)}
                                                            onChange={() => {
                                                                const newSelected = selectedAdmins.includes(admin.id)
                                                                    ? selectedAdmins.filter(id => id !== admin.id)
                                                                    : [...selectedAdmins, admin.id];
                                                                setSelectedAdmins(newSelected);
                                                            }}
                                                        />
                                                        <span className="tw-text-sm">{admin.name}</span>
                                                    </label>
                                                ))}
                                            </div>
                                            <div className="tw-border-t tw-border-gray-200 tw-p-2 tw-flex tw-justify-end">
                                                <button
                                                    onClick={() => setIsAdminDropdownOpen(false)}
                                                    className="tw-px-4 tw-py-1.5 tw-bg-[#103f4c] tw-text-white tw-rounded tw-text-sm hover:tw-bg-[#185c70]"
                                                >
                                                    Done
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {/* Selected Admins Display */}
                                <div className="tw-mt-2 tw-flex tw-flex-wrap tw-gap-2">
                                    {selectedAdmins.map(adminId => {
                                        const admin = admins.find(a => a.id === adminId);
                                        return (
                                            <div 
                                                key={adminId}
                                                className="tw-bg-gray-100 tw-rounded-full tw-px-3 tw-py-1 tw-text-sm tw-flex tw-items-center"
                                            >
                                                <span>{admin?.name}</span>
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setSelectedAdmins(selectedAdmins.filter(id => id !== adminId));
                                                    }}
                                                    className="tw-ml-2 tw-text-gray-500 hover:tw-text-gray-700"
                                                >
                                                    <X size={14} />
                                                </button>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                            {/* Message Input */}
                            <div className="tw-mb-6">
                                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-500 tw-mb-2">
                                    Additional Message
                                </label>
                                <textarea
                                    className="tw-w-full tw-border tw-border-gray-300 tw-rounded tw-p-2"
                                    rows={4}
                                    value={notificationMessage}
                                    onChange={(e) => setNotificationMessage(e.target.value)}
                                    placeholder="Enter additional details..."
                                />
                            </div>
                        </div>

                        {/* Footer */}
                        <div className="tw-p-6">
                            <div className="tw-flex tw-space-x-3">
                                <button
                                    className="tw-flex-1 tw-bg-[#103f4c] tw-text-white tw-py-2 tw-px-4 tw-rounded hover:tw-bg-[#185c70]"
                                    onClick={() => {
                                        // Handle notification submission here
                                        console.log('Notifying admins:', selectedAdmins);
                                        console.log('Message:', notificationMessage);
                                        setIsPanelOpen(false);
                                    }}
                                >
                                    Send Notification
                                </button>
                                <button
                                    className="tw-flex-1 tw-bg-gray-100 tw-text-gray-700 tw-py-2 tw-px-4 tw-rounded hover:tw-bg-gray-200"
                                    onClick={() => setIsPanelOpen(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <ToastManager ref={toastManagerRef} />
        </div>
    </>)
}

export default MonitoringDashboardPage;